import React, { useState } from "react";
import UploadContractModal from "./upload.contract.modal";
import styled from "styled-components";
import SecondaryButton from "../../../../app/components/buttons/secondary";

export default function UploadContract({ studyId, scheduleId, siteGroupId, siteId, siteNumber }) {
  const [fileUploadOpen, setFileUploadOpen] = useState(false);

  const handleFileUploadClose = () => {
    setFileUploadOpen(false);
    // Remove the page refresh here
  };

  const handleFileUploadContinue = () => {
    setFileUploadOpen(false);
    window.location.reload(); // Refresh the page only when "Continue" is clicked
  };

  return (
    <Container>
      <SecondaryButton onClick={() => setFileUploadOpen(true)}>
        UPLOAD SITE CONTRACT
      </SecondaryButton>
      {fileUploadOpen && (
        <UploadContractModal
          open={fileUploadOpen}
          onClose={handleFileUploadClose}
          onContinue={handleFileUploadContinue}
          studyId={studyId}
          scheduleId={scheduleId}
          siteGroupId={siteGroupId}
          siteId={siteId}
          siteNumber = {siteNumber}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;