import React, { useContext, useMemo, useState } from "react";

import API from "../utils/api";
import { getSequentialTableDefaults } from "./helpers";
import moment from "moment";
import { useSnackbar } from "./snackbar.context";

const StudyContext = React.createContext();

export function useStudyPlan() {
  return useContext(StudyContext);
}

const STUDY_PLAN_TYPES = {
  SCENARIO_A: 1,
  SCENARIO_B: 2,
};

export function StudyProvider({ children }) {
  // const [saChangeLog, setSAChangeLog] = useState({});
  // const [saLoading, setSALoading] = useState({});
  // const [saErrors, setSAErrors] = useState({});
  // const [saItems, setSAItems] = useState({});
  // const [saDeleteIds, setSADeleteIds] = useState({});

  const [studyPlan, setStudyPlan] = useState(null);
  const [seqCohorts, setSeqCohorts] = useState([]);
  const [seqCohortColumnHeaders, setSeqCohortColumnHeaders] = useState({
    columnHeader3: "DTL",
    columnHeader4: "SRC",
  });
  const [seqCohortChangeLog, setSeqCohortChangeLog] = useState({});
  const [seqCohortsStatus, setSeqCohortsStatus] = useState("idle");
  const [study, setStudy] = useState(null);
  const [changelog, setChangelog] = useState({});
  const [countryChangelog, setCountryChangelog] = useState({});
  const [countryMilestoneChangelog, setCountryMilestoneChangelog] = useState(
    {},
  );
  const [simulationResults, setSimulationResults] = useState({});
  const [studyActualsData, setStudyActualsData] = useState({});
  const [studyRemodelData, setStudyRemodelData] = useState({});

  const [loadingStudy, setLoadingStudy] = useState(true);
  const [loadingPlan, setLoadingPlan] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [actualsLoading, setActualsLoading] = useState(false);
  const [remodelLoading, setRemodelLoading] = useState(false);
  const [remodelUpdateLoading, setRemodelUpdateLoading] = useState(false);
  const [lActualsLoading, setLActualsLoading] = useState(false);
  const [deleteCountryLoading, setDeleteCountryLoading] = useState(false);
  const [isCountryUpdate, setIsCountryUpdate] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [isSequential, hideEnrollmentDuration] = useMemo(() => {
    const { studyPlanType, cohortType } = studyPlan || {};
    return [cohortType === 2, studyPlanType === 1 || cohortType === 2];
  }, [studyPlan]);

  const { showSnackbar } = useSnackbar();
  const canEditPlan = studyPlan?.scenarioStatus === "Draft";

  function deleteStudy(studyId, studyPlanId) {
    return API.deleteStudyPlan(studyId, studyPlanId);
  }

  function fetchStudy(id) {
    setLoadingStudy(true);
    return API.getStudy(id).then((res) => {
      setStudy(res.data[0]);
      setLoadingStudy(false);
    });
  }

  function fetchCohortStudyPlan(id) {
    setSeqCohortsStatus("loading");
    return API.getCohortStudyPlan(id)
      .then((res) => {
        const { studyPlanId } = studyPlan;
        if (res.data.length) {
          setSeqCohorts(res.data);
          setSeqCohortColumnHeaders({
            columnHeader3: res.data[0].columnHeader3 || "DTL",
            columnHeader4: res.data[0].columnHeader4 || "SRC",
          });
        } else {
          setSeqCohorts([getSequentialTableDefaults(studyPlanId)]);
        }
        setSeqCohortsStatus("resolved");
      })
      .catch((err) => {
        setSeqCohortsStatus("error");
      });
  }

  function updateCohortStudyPlan(id, noLoading = false, updatedValues = null) {
    if (!noLoading) {
      setSeqCohortsStatus("loading");
    }
    const localSeqCohorts = (updatedValues || seqCohorts).map((sc) => {
      const lsc = { ...sc };
      delete lsc.key;
      const keys = Object.keys(lsc);
      keys.forEach((key) => {
        if (
          [
            "cohortStartDate",
            "dtlEndDate",
            "enrollingEndDate",
            "screeningEndDate",
            "srcEndDate",
          ].includes(key)
        ) {
          if (lsc[key])
            lsc[key] = moment.utc(lsc[key], "D-MMM-YY").format("YYYY-MM-DD");
        }
      });
      return { ...lsc, ...seqCohortColumnHeaders };
    });

    return API.updateCohortStudyPlan(id, localSeqCohorts)
      .then(async (res) => {
        if (!noLoading) {
          const { studyPlanId } = studyPlan;
          await fetchCohortStudyPlan(studyPlanId);
          setSaveLoading(false);
        }
      })
      .catch((err) => {
        setSeqCohortsStatus("error");
        if (!noLoading) setSaveLoading(false);
      });
  }

  function validateSeasonalVariations() {
    let valid = true;
    const countries = studyPlan.countries;
    countries.forEach((country) => {
      country.siteGroups.forEach((siteGroup) => {
        siteGroup.enrollmentVariations.forEach((ev) => {
          const value = (ev.enrollmentSeasonalVariation ?? "").toString();
          if (
            value === "" ||
            isNaN(value) ||
            parseFloat(value) > 1 ||
            parseFloat(value) < 0
          )
            valid = false;
        });
      });
    });
    return valid;
  }

  function validateSequentialCohort(pathname) {
    if (
      Object.keys(seqCohortChangeLog).length === 0 ||
      !(
        pathname.includes("countries-and-site-groups") ||
        pathname.includes("review")
      )
    )
      return true;

    let valid = true;
    seqCohorts.forEach((sc, index) => {
      let keys = Object.keys(sc);
      keys.forEach((key) => {
        if (index === 0) {
          if (!["predecessorCohortName", "predecessorActivity"].includes(key)) {
            if (!sc[key] && sc[key] !== 0) valid = false;
          }
        } else {
          if (!sc[key] && sc[key] !== 0) valid = false;
        }
      });
    });

    return valid;
  }

  function fetchStudyPlan(id) {
    setLoadingPlan(true);
    setSeqCohortsStatus("idle");
    return API.getStudyPlan(id).then((res) => {
      setStudyPlan(res.data[0]);
      setLoadingPlan(false);
    });
  }

  function updatePlan(property, value) {
    const { studyPlanId } = studyPlan;
    const newStudyPlan = { ...studyPlan, [property]: value };
    setChangelog((prev) => ({ studyPlanId, ...prev, [property]: value }));
    setStudyPlan(newStudyPlan);
  }

  async function savePlan({
    isNew = false,
    redirect = undefined,
    onClose = undefined,
    from = undefined,
    fromActivation = false,
  }) {
    const { studyPlanId, studyId } = studyPlan;

    if (isNew) {
      API.createNewPlan(studyPlan).then((res) => {
        setChangelog({});
        redirect && redirect(res.data[0].Id);
        setSaveLoading(false);
      });
    } else {
      // For user updates, ensure the last activation profile matches Number of Sites
      if (changelog.countries) {
        changelog.countries.forEach(country => {
          if (country.siteGroups?.[0]) {
            const siteGroup = country.siteGroups[0];
            const sitesParam = siteGroup.parameters?.find(p => p.parameterName === "SITES_TO_ACTIVATE");
            const totalSites = sitesParam?.upperBound ?? sitesParam?.lowerBound;
            
            if (totalSites && siteGroup.activationProfiles?.length) {
              const lastIndex = siteGroup.activationProfiles
                .map((profile, index) => ({ hasValue: profile.siteActivationProfilePct !== null, index }))
                .filter(item => item.hasValue)
                .pop()?.index;

              if (lastIndex !== undefined) {
                siteGroup.activationProfiles[lastIndex].siteActivationProfilePct = parseInt(totalSites, 10);
              }
            }
          }
        });
      }

      if (isSequential) await updateCohortStudyPlan(studyId);

      Object.keys(studyPlan).forEach((key) => {
        if (!changelog.hasOwnProperty(key)) {
          changelog[key] = studyPlan[key];
        }
      });

      const lowerBound =
        changelog.countries[0]?.siteGroups[0]?.parameters[0]?.lowerBound;

      function getSiteIdSize(lowerBound) {
        if (lowerBound >= 1 && lowerBound <= 2) {
          return "Small";
        }
        if (lowerBound >= 3 && lowerBound <= 10) {
          return "Medium";
        }
        if (lowerBound > 10) {
          return "Large";
        }
        return "";
      }
      const size = getSiteIdSize(lowerBound);

      if (changelog?.countries?.[0]?.siteGroups?.[0]) {
        changelog.countries[0].siteGroups[0].timeSiteIdSize = size ?? 0;
      }
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      function convertMillisToDateString(millis) {
        const date = new Date(millis);
        const day = String(date.getDate()).padStart(2, "0");
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }

      const transformedObject = {
        studyId: studyId,
        studyPlanId: changelog.studyPlanId,
        studyPlanType: changelog.studyPlanTypeDetail,
        tables: [
          {
            countries: changelog.countries.map((country) => {
              const NUM_SUBJECTS_COMPLETED =
                country.siteGroups[0].milestones.find(
                  (m) =>
                    m.milestoneDescr ===
                    "Number of Patients Completing Follow-Up",
                );

              const NUM_SUBJECTS_TREATED =
                country.siteGroups[0].milestones.find(
                  (m) =>
                    m.milestoneDescr ===
                    "Number of Patients Completing Treatment",
                );

              const NUM_SUBJECTS_ENROLLED =
                country.siteGroups[0].milestones.find(
                  (m) => m.milestoneDescr === "Number of Patients Enrolled",
                );

              return {
                countryName: country.countryName,
                countryStartUpTimeFrame:
                  country.siteGroups[0].timeCountryStartUpSize,
                enrollmentRate: country.siteGroups[0].siteEnrollmentRateAtSIV,
                NUM_SITES_ACTIVATED_LOWER:
                  country.siteGroups[0].parameters[0].lowerBound,
                siteIdEffort: country.siteGroups[0].timeSiteIdSize,
                NUM_SITES_ACTIVATED_UPPER:
                  country.siteGroups[0].parameters[0].upperBound,
                NUM_SUBJECTS_COMPLETED: NUM_SUBJECTS_COMPLETED
                  ? NUM_SUBJECTS_COMPLETED.lowerBound
                  : "",
                NUM_SUBJECTS_ENROLLED: NUM_SUBJECTS_ENROLLED
                  ? NUM_SUBJECTS_ENROLLED.lowerBound
                  : "",
                NUM_SUBJECTS_SCREENED_LOWER:
                  country.siteGroups[0].parameters[1].lowerBound,
                NUM_SUBJECTS_SCREENED_UPPER:
                  country.siteGroups[0].parameters[1].upperBound,
                NUM_SUBJECTS_TREATED: NUM_SUBJECTS_TREATED
                  ? NUM_SUBJECTS_TREATED.lowerBound
                  : "",
                dropOutRate: country.siteGroups[0].expectedTreatmentDropoutRate,
                screenFailRate: country.siteGroups[0].expectedScreenFailureRate,
              };
            }),
            tableName: "Country Inputs",
          },
          {
            countries: changelog.countries.map((country) => {
              const SITE_ID = country.siteGroups[0].milestones.find(
                (m) => m.milestoneName === "DURATION_TO_SITE_ID_FINISH",
              );

              const SUBMISSION = country.siteGroups[0].milestones.find(
                (m) => m.milestoneName === "DURATION_TO_SUBMISSION",
              );

              const FIRST_REGULATORY = country.siteGroups[0].milestones.find(
                (m) =>
                  m.milestoneName === "DURATION_TO_FIRST_REGULATORY_APPROVAL",
              );

              const FSIV = country.siteGroups[0].milestones.find(
                (m) => m.milestoneName === "DURATION_TO_FSIV",
              );

              const FPS = country.siteGroups[0].milestones.find(
                (m) => m.milestoneName === "DURATION_TO_FPS",
              );

              const FPI = country.siteGroups[0].milestones.find(
                (m) => m.milestoneName === "DURATION_TO_FPI",
              );

              const LPI = country.siteGroups[0].milestones.find(
                (m) => m.milestoneName === "DURATION_TO_LPI",
              );
              return {
                countryName: country.countryName,
                SITE_ID_DATE: SITE_ID
                  ? convertMillisToDateString(SITE_ID.milestoneDateMillis)
                  : "",
                SITE_ID_COMMENT: SITE_ID ? SITE_ID.comments : "",
                SUBMISSION_DATE: SUBMISSION
                  ? convertMillisToDateString(SUBMISSION.milestoneDateMillis)
                  : "",
                SUBMISSION_COMMENT: SUBMISSION ? SUBMISSION.comments : "",
                FIRST_REGULATORY_APPROVAL_DATE: FIRST_REGULATORY
                  ? convertMillisToDateString(
                      FIRST_REGULATORY.milestoneDateMillis,
                    )
                  : "",
                FIRST_REGULATORY_APPROVAL_COMMENT: FIRST_REGULATORY
                  ? FIRST_REGULATORY.comments
                  : "",
                FSIV_DATE: FSIV
                  ? convertMillisToDateString(FSIV.milestoneDateMillis)
                  : "",
                FSIV_COMMENT: FSIV ? FSIV.comments : "",
                FPS_DATE: FPS
                  ? convertMillisToDateString(FPS.milestoneDateMillis)
                  : "",
                FPS_COMMENT: FPS ? FPS.comments : "",
                FPI_DATE: FPI
                  ? convertMillisToDateString(FPI.milestoneDateMillis)
                  : "",
                FPI_COMMENT: FPI ? FPI.comments : "",
                LPI_DATE: LPI
                  ? convertMillisToDateString(LPI.milestoneDateMillis)
                  : "",
                LPI_COMMENT: LPI ? LPI.comments : "",
              };
            }),
            tableName: "Milestones",
          },
          {
            countries: changelog.countries.flatMap((country) => {
              const profileObject = {
                countryName: country.countryName,
              };

              let index = 0;

              country.siteGroups[0].activationProfiles.forEach((profile) => {
                index++;
                const cardPercent = `CARD_${index}_PERCENT`;
                const cardDate = `CARD_${index}_DAYS`;

                profileObject[cardPercent] = parseFloat(
                  profile.siteActivationProfilePct
                    ? profile.siteActivationProfilePct
                    : undefined,
                );

                profileObject[cardDate] = profile.siteActivationProfilePct
                  ? profile.siteActivationProfileDays
                  : undefined;
              });

              return [profileObject];
            }),
            tableName: "Activation Profiles",
          },
          {
            countries: changelog.countries.flatMap((country) => {
              const monthsObject = {
                countryName: country.countryName,
              };

              country.siteGroups[0].enrollmentVariations.forEach((month) => {
                const monthKey = `MONTH_${month.enrollmentSeasonalMonth + 1}`;
                monthsObject[monthKey] = parseFloat(
                  month.enrollmentSeasonalVariation,
                );
              });

              return [monthsObject];
            }),
            tableName: "Enrollment Seasonal Variations",
          },
          {
            tableName: "Site Fatigue",
            countries: changelog.countries.map((country) => {
              return {
                countryName: country.countryName,
                SITE_FATIGUE_DAYS:
                  country.siteGroups[0].eventualSiteEnrollmentDays || undefined,
                SITE_FATIGUE_PERCENT:
                  country.siteGroups[0].eventualSiteEnrollmentRate || undefined,
              };
            }),
          },
        ],
      };

      if (studyPlan.ActualStep === 1) {
        const seqChange = Object.keys(seqCohortChangeLog).length;
        const changeLogKeys = Object.keys(changelog);
        const countryUpdate =
          changeLogKeys.length === 2 && !!changelog["countries"];
        if (!countryUpdate && Object.keys(changelog).length) {
          API.updatePlan(changelog, studyId)
            .then(async (res) => {
              setSaveLoading(false);
              setStudyPlan(changelog);
              showSnackbar("Successfully saved plan.", "success");
              setChangelog({});
              redirect && redirect();
              onClose && onClose();
              if (!seqChange && isSequential)
                await fetchCohortStudyPlan(studyPlanId);
            })
            .catch((err) => {
              setSaveLoading(false);
              showSnackbar("Failed to save plan.", "error");
            });
        }
      }

      if (studyPlan.ActualStep === 2) {
        API.updatePlanStep2(transformedObject)
          .then(async (res) => {
            if (
              Number(res.status) === 200 &&
              Number(res.data[0].returnCode) === 200
            ) {
              studyPlan.countries[0].siteGroups[0].milestones.forEach(
                (milestone) => {
                  const element = res.data.find(
                    (element) =>
                      element.studyPlanMilestoneGoalId ===
                        milestone.studyPlanMilestoneGoalId &&
                      element.countryId === milestone.countryId,
                  );

                  if (element) {
                    milestone.milestoneDateMillis = element.milestoneDateMillis;
                  }
                },
              );

              setStudyPlan(studyPlan);
              setChangelog({});
              setSeqCohortChangeLog({});
              setCountryChangelog({});
              showSnackbar("Successfully saved plan.", "success");
              if (redirect) {
                await fetchStudyPlan(studyPlanId);
                setSaveLoading(false);
                redirect();
              }
              onClose && onClose();
            } else {
              showSnackbar("Failed to save plan.", "error");
            }
          })
          .catch((err) => {
            setSaveLoading(false);
            showSnackbar("Failed to save plan.", "error");
          });
        setSaveLoading(false);
      }
    }
  }

  function updateStudyPlan(updates) {
    const { studyPlanId, studyId } = studyPlan;
    const body = {
      studyPlanId,
      studyId,
      ...updates,
    };
    API.updateStudyPlan(body, studyId).then((res) => {
      setStudyPlan({ ...studyPlan, ...updates });
    });
  }

  function quickSave(updates) {
    const { studyPlanId } = studyPlan;
    const body = {
      studyPlanId,
      ...updates,
    };
    API.updatePlan(body).then((res) => {
      setStudyPlan({ ...studyPlan, ...updates });
    });
  }

  function updateCountries(
    countryId,
    siteGroupId,
    update,
    isDelete = false,
    s,
  ) {
    const { studyPlanId } = studyPlan;
    if (!isDelete) {
      setCountryChangelog((prev) => ({
        ...prev,
        [countryId]: {
          studyPlanId,
          countryId,
          siteGroupId,
          ...prev[countryId],
          ...update,
        },
      }));
    }

    _countryStateUpdate(countryId, update);
  }

  function updateCountryMilestones(
    countryId,
    siteGroupId,
    update,
    changelog_update,
    isDelete = false,
  ) {
    const { studyPlanId } = studyPlan;
    if (!isDelete) {
      setCountryMilestoneChangelog((prev) => ({
        ...prev,
        [countryId]: {
          studyPlanId,
          countryId,
          siteGroupId,
          ...prev[countryId],
          milestones: [changelog_update[0]],
        },
      }));
    }

    _countryStateUpdate(countryId, update);
  }

  function addCountry(new_country) {
    const { countries } = studyPlan;
    countries.push(new_country);
    setStudyPlan({ ...studyPlan, countries });
  }

  async function _countryStateUpdate(countryId, update) {
    const countryIndex = studyPlan.countries
      .map((country) => country.countryId)
      .indexOf(countryId);
    const country = studyPlan.countries[countryIndex];
    const updated_site_group = { ...country.siteGroups[0], ...update };
    const new_country = { ...country };
    new_country.siteGroups[0] = updated_site_group;
    const new_countries = [...studyPlan.countries];
    new_countries[countryIndex] = new_country;

    updatePlan("countries", new_countries);
  }

  async function saveCountries(redirect = undefined, onClose = undefined) {
    const { studyPlanId } = studyPlan;

    let keys = Object.keys(countryChangelog);
    let res = {};
    try {
      for (let i = 0; i < keys.length; i++) {
        const body = {
          studyPlanId,
          countryId: keys[i],
          siteGroups: [countryChangelog[keys[i]]],
        };
        res = await API.updateCountry(body);
      }

      showSnackbar("Save was successful!", "success");
      setCountryChangelog({});
      setIsCountryUpdate(false);
      redirect && redirect();
      onClose && onClose();
      setStudyPlan(res.data);
      setSaveLoading(false);
    } catch (err) {
      showSnackbar("Not all saves were successful.", "error");
      setSaveLoading(false);
    }
  }

  function saveCountryMilestone(redirect = undefined, onClose = undefined) {
    const { studyPlanId } = studyPlan;

    const countryId = Object.keys(countryMilestoneChangelog)[0];

    const body = {
      studyPlanId,
      countryId,
      siteGroups: [countryMilestoneChangelog[countryId]],
    };

    API.updateCountry(body)
      .then((res) => {
        showSnackbar("Successfully saved country milestone!", "success");
        setCountryMilestoneChangelog({});
        setStudyPlan(res.data);
        setSaveLoading(false);
        onClose && onClose();
      })
      .catch((err) => {
        console.log(err);
        showSnackbar("Failed to save country milestone.", "error");
        setSaveLoading(false);
      });
  }

  const createNewCohortPlan = (
    selectedStudyPlans,
    studyId,
    values,
    redirect,
  ) => {
    if (selectedStudyPlans.length < 2) return;
    const combinedPlans = selectedStudyPlans.map((ssp) => ({
      studyId: ssp?.studyId,
      studyPlanId: ssp?.studyPlanId,
      studyPlanName: values?.studyPlanName,
      studyPlanDescr: values?.studyPlanDescr,
    }));
    setSaveLoading(true);
    API.createNewCohortPlan(combinedPlans, studyId)
      .then((res) => {
        setSaveLoading(false);
        setChangelog({});
        if (res.data[0].returnCode === "200") {
          showSnackbar("Study plan created successfully.", "success");
          setStudyPlan({
            studyPlanId: res.data[0].studyPlanId,
            studyId: studyId,
          });
          redirect && redirect(res.data[0].studyPlanId);
        } else {
          showSnackbar(
            "Unable to create study plan. Please try again.",
            "error",
          );
        }
      })
      .catch((err) => {
        showSnackbar("Unable to create study plan. Please try again.", "error");
      });
  };

  const createNewPlan = async (scenario, sequentialCohort = "false") => {
    clearStudyPlan();
    setLoadingPlan(true);
    const lSequentialCohort = JSON.parse(sequentialCohort);

    const newPlan = {
      studyId: study.studyId,
      projectId: study.projectId,
      studyPlanType: scenario === "scenario-b" ? 2 : 1,
      pcn: study.projectId,
      studyName: study.studyName,
      sponsorName: study.sponsorName,
      indication: "",
      protocolTitle: "",
      studyPlanName: "",
      studyPlanDescr: "",
      studyPlanVersion: 1,
      croProjectStartDateMillis: null,
      finalProtocolStartDateMillis: null,
      croProjectStartToSiteIdDuration: null,
      siteIdToFinalProtocolDuration: null,
      regApprovalToFSIVDuration: null,
      screeningPeriodDuration: null,
      treatmentDuration: null,
      followUpDuration: null,
      tfedToDbLockDuration: null,
      dbLocktoCSRDuration: null,
      csrToTMFCloseAndReturnDuration: null,
      enrollmentRate: null,
      screenFailRate: null,
      dropOutRate: null,
      overallEnrollmentDuration: null,
      pauseEnrollmentAfterPatient: "",
      pauseEnrollmentDays: "",
      simOptSecondsSpentLimit: null,
      simOptUnimprovedScoreCalculationLimit: null,
      simOptRunId: null,
      simOptRunStatus: null,
      simOptRunMsg: null,
      clonedStudyPlanId: null,
      comments: "",
      scenarioStatus: "Draft",
      cohortType: lSequentialCohort ? 2 : 0,
      opportunityStage: "(unassigned)",
      isPersonalDraft: 1,
      templateFlag: 0,
      ownedBy: "",
      publishBy: null,
      publishTs: null,
      parameters: [
        {
          parameterScope: "STUDY",
          parameterId: 12,
          parameterName: "SITES_TO_ACTIVATE",
          parameterDescr: "Total number of sites to activate",
          lowerBound: null,
          upperBound: null,
          comments: "",
        },
        {
          parameterScope: "STUDY",
          parameterId: 13,
          parameterName: "SUBJECTS_TO_SCREEN",
          parameterDescr: "Total number of patients to screen",
          lowerBound: null,
          upperBound: null,
          comments: "",
        },
      ],
      milestones: [
        {
          milestoneScope: "STUDY",
          milestoneId: 138,
          milestoneName: "NUM_SITES_ACTIVATED",
          milestoneDescr: "Total number of sites activated",
          milestoneLabel: "Number of Activated Sites",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 141,
          milestoneName: "NUM_SUBJECTS_SCREENED",
          milestoneDescr: "Total number of patients screened",
          milestoneLabel: "Number of Patients Screened",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 144,
          milestoneName: "NUM_SUBJECTS_ENROLLED",
          milestoneDescr: "Total number of patients enrolled",
          milestoneLabel: "Number of Patients Enrolled",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 147,
          milestoneName: "NUM_SUBJECTS_TREATED",
          milestoneDescr: "Total number of patients treated",
          milestoneLabel: "Number of Patients Completing Treatment",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 150,
          milestoneName: "NUM_SUBJECTS_COMPLETED",
          milestoneDescr:
            "Total number of patients who completed the entire study (including follow-up)",
          milestoneLabel: "Number of Patients Completing Follow-Up",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 134,
          milestoneName: "DURATION_TO_SITE_ID",
          milestoneDescr: "Duration [days] from study start to site ID",
          milestoneLabel: "Site ID Finish",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 135,
          milestoneName: "DURATION_TO_SUBMISSION",
          milestoneDescr: "Duration [days] from study start to submission",
          milestoneLabel: "Regulartory Submission",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 136,
          milestoneName: "DURATION_TO_FIRST_REGULATORY_APPROVAL",
          milestoneDescr:
            "Duration [days] from Submission Date to first regulatory approval",
          milestoneLabel: "First Regulatory Approval",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 139,
          milestoneName: "DURATION_TO_FSIV",
          milestoneDescr:
            "Duration [days] from study start to first site initiation visit (FSIV)",
          milestoneLabel: "FSIV",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 142,
          milestoneName: "DURATION_TO_FPS",
          milestoneDescr:
            "Duration [days] from study start to first patient screened (FPS)",
          milestoneLabel: "First Patient Screened (FPS)",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 145,
          milestoneName: "DURATION_TO_FPI",
          milestoneDescr:
            "Duration [days] from study start to first patient in (FPI) / first patient first visit (FPFV)",
          milestoneLabel: "First Patient Enrolled (FPE)",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 146,
          milestoneName: "DURATION_TO_LPI",
          milestoneDescr:
            "Duration [days] from study start to last patient in (LPI) / last patient first visit (LPFV)",
          milestoneLabel: "Last Patient Enrolled (LPE)",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 148,
          milestoneName: "DURATION_TO_FPLV",
          milestoneDescr:
            "Duration [days] study start to first patient last visit (FPLV) / first patient last visit (FPLV)",
          milestoneLabel: "First Patient Completes Treatment",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 149,
          milestoneName: "DURATION_TO_LPLV",
          milestoneDescr:
            "Duration [days] from study start to last patient last visit (LPLV) / last patient last visit (LPLV)",
          milestoneLabel: "Last Patient Completes Treatment",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 151,
          milestoneName: "DURATION_TO_FPC",
          milestoneDescr:
            "Duration [days] from study start to first patient completed (FPC)",
          milestoneLabel: "First Patient Last Follow-Up Visit",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
        {
          milestoneScope: "STUDY",
          milestoneId: 152,
          milestoneName: "DURATION_TO_TFED",
          milestoneDescr:
            "Duration [days] study start to last patient completed (LPC) / treatment follow-up end date (TFED)",
          milestoneLabel: "Last Patient Last Follow-Up Visit",
          lowerBound: null,
          upperBound: null,
          levelOfConfidence: -1,
          comments: "",
          milestoneDateMillis: null,
        },
      ],
      countries: [],
    };

    setStudyPlan(newPlan);
    setLoadingPlan(false);
  };

  const removeCountry = (body, studyId, countryId, cb) => {
    setDeleteCountryLoading(true);
    API.deleteCountry(body, studyId)
      .then((res) => {
        cb();

        setDeleteCountryLoading(false);

        const newStudyPlan = { ...studyPlan };

        newStudyPlan.countries = newStudyPlan.countries.filter(
          (item) => item.countryId !== countryId,
        );

        setStudyPlan(newStudyPlan);

        showSnackbar("Successfully removed country.", "success");
      })
      .catch((err) => {
        setDeleteCountryLoading(false);
        showSnackbar("Failed to remove country.", "error");
      });
  };

  const clearChangeLog = () => {
    setChangelog({});
    setCountryChangelog({});
    setSeqCohortChangeLog({});
    setCountryMilestoneChangelog({});
  };
  const clearStudyPlan = () => {
    setStudyPlan(null);
    clearChangeLog();
  };

  const resetStudyPlanWithCountry = (studyPlan, deletedCountryId) => {
    const { countries } = studyPlan;
    const lCountries = [];
    let lCountryChangeLog = countryChangelog;
    if (deletedCountryId) {
      lCountryChangeLog = {};
      Object.keys(countryChangelog).map((key) => {
        if (key !== deletedCountryId) {
          lCountryChangeLog[key] = countryChangelog[key];
        }
        return "";
      });
    }
    for (let i = 0; i < countries.length; i++) {
      let c = countries[i];
      if (lCountryChangeLog[c.countryId]) {
        lCountries.push({
          ...c,
          siteGroups: [
            { ...c["siteGroups"][0], ...lCountryChangeLog[c.countryId] },
          ],
        });
      } else {
        lCountries.push(c);
      }
    }
    setStudyPlan({ ...studyPlan, countries: lCountries });
    if (deletedCountryId) setCountryChangelog(lCountryChangeLog);
  };

  const resetStudyPlan = (studyPlan) => {
    setStudyPlan(studyPlan);
    clearChangeLog();
  };

  const fetchMilestoneDates = (body) => API.getMilestoneDates(body);

  const fetchAllCountries = async () => {
    if (allCountries.length === 0) {
      API.getCountries().then((res) => {
        setAllCountries((prev) => [...prev, ...res.data]);
      });
    }
  };

  const runSimulation = (id = undefined) => {
    setSimulationResults({});
    if (id) {
      API.runSimulation({ studyPlanId: id }).then((res) => {});
    } else {
      API.runSimulation({ studyPlanId: studyPlan.studyPlanId }).then(
        (res) => {},
      );
    }
  };

  const checkSimulationStatus = async () => {
    return await API.checkSimulationStatus(studyPlan.studyPlanId).then(
      (res) => res.data,
    );
  };
  const fetchSimulationResults = async () =>
    API.getSimulationResults(studyPlan.studyPlanId).then((res) =>
      setSimulationResults((prev) => ({ ...prev, ...res.data })),
    );

  const fetchSimulationDetailsByMonth = async (studyType = "results") =>
    API.getSimulationDetailTableByMonth(studyPlan.studyPlanId, studyType)
      .then((res) => {
        if (studyType === "results") {
          setSimulationResults((prev) => ({
            ...prev,
            detailsByMonth: res.data,
          }));
        } else {
          setSimulationResults((prev) => ({
            ...prev,
            detailsByMonthCohort: res.data,
          }));
        }
      })
      .catch((error) => console.log(error));

  const fetchSimulationDetailsByMonthNonCumulative = async (
    studyType = "results",
  ) =>
    API.getSimulationDetailTableByMonthNonCumulative(
      studyPlan.studyPlanId,
      studyType,
    )
      .then((res) => {
        if (studyType === "results") {
          setSimulationResults((prev) => ({
            ...prev,
            detailsByMonthNonCumulative: res.data,
          }));
        } else {
          setSimulationResults((prev) => ({
            ...prev,
            detailsByMonthNonCumulativeCohort: res.data,
          }));
        }
      })
      .catch((error) => console.log(error));

  const fetchSimulationDetailsByWeekNonCumulative = async (
    studyType = "results",
  ) =>
    API.getSimulationDetailTableByWeekNonCumulative(
      studyPlan.studyPlanId,
      studyType,
    )
      .then((res) => {
        if (studyType === "results") {
          setSimulationResults((prev) => ({
            ...prev,
            detailsByWeekNonCumulative: res.data,
          }));
        } else {
          setSimulationResults((prev) => ({
            ...prev,
            detailsByWeekNonCumulativeCohort: res.data,
          }));
        }
      })
      .catch((error) => console.log(error));

  const fetchSimulationDetailsByWeek = async (studyType = "results") =>
    API.getSimulationDetailTableByWeek(studyPlan.studyPlanId, studyType)
      .then((res) => {
        if (studyType === "results") {
          setSimulationResults((prev) => ({
            ...prev,
            detailsByWeek: res.data,
          }));
        } else {
          setSimulationResults((prev) => ({
            ...prev,
            detailsByWeekCohort: res.data,
          }));
        }
      })
      .catch((error) => console.log(error));

  const fetchSimulationEnrollmentTable = async () =>
    API.getSimulationEnrollmentTable(studyPlan.studyPlanId)
      .then((res) =>
        setSimulationResults((prev) => ({
          ...prev,
          enrollmentTable: res.data,
        })),
      )
      .catch((error) => console.log(error));

  const fetchSimulationStartupTable = async () =>
    API.getSimulationStartupTable(studyPlan.studyPlanId)
      .then((res) =>
        setSimulationResults((prev) => ({
          ...prev,
          startupTable: res.data,
        })),
      )
      .catch((error) => console.log(error));

  const fetchSimulationSummaryTable = async (studyType = "results") =>
    API.getSimulationSummaryTable(studyPlan.studyPlanId, studyType)
      .then((res) => {
        if (studyType === "results") {
          setSimulationResults((prev) => ({ ...prev, summaryTable: res.data }));
        } else {
          setSimulationResults((prev) => ({
            ...prev,
            summaryTableCohort: res.data,
          }));
        }
      })
      .catch((error) => console.log(error));

  const fetchSimulationDurationTable = async () =>
    API.getSimulationDurationTable(studyPlan.studyPlanId)
      .then((res) =>
        setSimulationResults((prev) => ({
          ...prev,
          durationTable: res.data,
        })),
      )
      .catch((error) => console.log(error));

  const fetchSimulationTimelineTable = async () =>
    API.getSimulationTimelineTable(studyPlan.studyPlanId)
      .then((res) =>
        setSimulationResults((prev) => ({
          ...prev,
          timelineTable: res.data,
        })),
      )
      .catch((error) => console.log(error));

  const fetchOverallChart = async () =>
    API.getOverallChart(studyPlan.studyPlanId)
      .then((res) => {
        setSimulationResults((prevState) => {
          return {
            ...prevState,
            overallChart: res.data.Study?.table_body.Group,
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });

  const clearSimData = () => setSimulationResults({});

  const fetchCountryChart = async () =>
    API.getCountryChart(studyPlan.studyPlanId)
      .then((res) => {
        setSimulationResults((prevState) => {
          return {
            ...prevState,
            countryChart: res.data.Study?.table_body.Group,
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });

  const fetchRegionChart = async () => {
    API.getRegionChart(studyPlan.studyPlanId)
      .then((res) => {
        setSimulationResults((prevState) => {
          return {
            ...prevState,
            regionChartMonthly: res.data.Study?.table_body,
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });
    API.getRegionChart(studyPlan.studyPlanId, "WEEK")
      .then((res) => {
        setSimulationResults((prevState) => {
          return {
            ...prevState,
            regionChartWeekly: res.data.Study?.table_body,
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchSiteGroupChart = async () => {
    API.getSiteGroupChart(studyPlan.studyPlanId, "MONTH")
      .then((res) => {
        setSimulationResults((prevState) => {
          return {
            ...prevState,
            siteGroupChartMonthly: res.data.Study?.table_body,
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });
    API.getSiteGroupChart(studyPlan.studyPlanId)
      .then((res) => {
        setSimulationResults((prevState) => {
          return {
            ...prevState,
            siteGroupChartWeekly: res.data.Study?.table_body,
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchCohortChartMonth = async () => {
    API.getCohortChartMonthly(studyPlan.studyPlanId)
      .then((res) => {
        setSimulationResults((prevState) => {
          return {
            ...prevState,
            cohortChartMonthly: res.data.Study?.table_body,
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchCohortChartWeek = async () => {
    API.getCohortChartWeekly(studyPlan.studyPlanId)
      .then((res) => {
        setSimulationResults((prevState) => {
          return {
            ...prevState,
            cohortChartWeekly: res.data.Study?.table_body,
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchCohortTimelineChart = async () => {
    API.getCohortTimelineChart(studyPlan.studyPlanId)
      .then((res) => {
        setSimulationResults((prevState) => {
          return {
            ...prevState,
            cohortTimelineChart: res.data,
          };
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchActualsTable = async () => {
    setActualsLoading(true);
    API.getStudyActuals(study.studyId)
      .then((res) => {
        setActualsLoading(false);
        setStudyActualsData((prevState) => {
          return {
            ...prevState,
            actualsData: res.data,
          };
        });
      })
      .catch((err) => {
        setActualsLoading(false);
        console.error(err);
      });
  };

  const fetchStudyRemodel = async () => {
    setRemodelLoading(true);
    API.getStudyRemodel(study.studyId)
      .then((res) => {
        setStudyRemodelData((prevState) => {
          return {
            ...prevState,
            ...(res.data[0] || {}),
          };
        });
        setRemodelLoading(false);
      })
      .catch((err) => {
        setRemodelLoading(false);
        console.error(err);
      });
  };

  const updateStudyRemodel = async () => {
    setRemodelUpdateLoading(true);
    return API.updateStudyRemodel(study.studyId, studyRemodelData)
      .then((res) => {
        setRemodelUpdateLoading(false);
        return res;
      })
      .catch((err) => {
        setRemodelUpdateLoading(false);
        throw err;
      });
  };

  const loadStudyActuals = async () => {
    setLActualsLoading(true);
    API.loadStudyActuals(study.studyId)
      .then((res) => {
        setLActualsLoading(false);
        setStudyActualsData((prevState) => {
          return {
            ...prevState,
            loadActualsData: res.data,
            actualsData: res.data,
          };
        });
      })
      .catch((err) => {
        setLActualsLoading(false);
        console.error(err);
      });
  };

  // const saCreateNew = (siteGroupId, countryId = null, studyPlanId = null) => {
  //   const newList = saItems[siteGroupId]
  //     .filter((item) => item.id !== undefined)
  //     ?.map((item) => ({ countryId, siteGroupId, studyPlanId, ...item }));

  //make new items API
  //   newList.forEach((item) =>
  //     API.createActivationProfiles(item)
  //       .then((res) => {
  //         const new_profile = res.data[0].data[0];
  //         const index = saItems[siteGroupId]
  //           ?.map((item) => item.id)
  //           .indexOf(item.id);
  //         const new_items = [...saItems[siteGroupId]];
  //         new_items[index] = new_profile;

  //         setSAItems({ ...saItems, [siteGroupId]: new_items });
  //       })
  //       .catch((err) =>
  //         setSAErrors({
  //           ...saErrors,
  //           [siteGroupId]: [...saErrors[siteGroupId], err],
  //         }),
  //       ),
  //   );
  // };

  // const saEditOld = (siteGroupId) => {
  //   Object.keys(saChangeLog[siteGroupId]).forEach((item) => {
  //     const index = saItems[siteGroupId]
  //       ?.map((item) => item.siteActivationProfileId)
  //       .indexOf(parseInt(item));
  //     API.editActivationProfiles(saItems[siteGroupId][index])
  //       .then(async (res) => {
  //         // await fetchStudyPlan(studyPlan.studyPlanId);
  //         // console.log("EDITED PROFILES", res);
  //       })
  //       .catch((err) =>
  //         setSAErrors({
  //           ...saErrors,
  //           [siteGroupId]: [...saErrors[siteGroupId], err],
  //         }),
  //       );
  //   });
  // };

  // const saDelete = (siteGroupId) => {
  //   saDeleteIds[siteGroupId].forEach((item) => {
  //     API.deleteActivationProfiles(item)
  //       .then((res) => {})
  //       .catch((err) =>
  //         setSAErrors({
  //           ...saErrors,
  //           [siteGroupId]: [...saErrors[siteGroupId], err],
  //         }),
  //       );
  //   });
  // };

  // const saSaveActivationProfiles = async (savePlanValues = false) => {
  //   const createIds = Object.keys(saItems);

  //   createIds.forEach((siteGroupId) => {
  //     saCreateNew(siteGroupId);
  //   });

  //   const deleteIds = Object.keys(saDeleteIds);
  //   deleteIds.forEach((siteGroupId) => {
  //     saDelete(siteGroupId);
  //   });

  //   const editIds = Object.keys(saChangeLog);
  //   editIds.forEach((siteGroupId) => {
  //     saEditOld(siteGroupId);
  //   });

  //   if (savePlanValues) {
  //     return await savePlan({ fromActivation: true });
  //   }
  // };

  let siteGroupId = 0;
  if (
    studyPlan &&
    studyPlan.countries &&
    studyPlan.countries[0] &&
    studyPlan.countries[0].siteGroups &&
    studyPlan.countries[0].siteGroups[0] &&
    studyPlan.countries[0].siteGroups[0].siteGroupId
  ) {
    siteGroupId = studyPlan.countries[0].siteGroups[0].siteGroupId;
  }
  const value = {
    deleteStudy,
    study,
    studyPlan,
    loadingStudy,
    loadingPlan,
    unsavedChange:
      Object.keys({
        ...changelog,
        ...countryChangelog,
        // ...saChangeLog[siteGroupId],
      }).length > 0 ||
      (studyPlan?.studyPlanType === 2 &&
        studyPlan?.studyPlanTypeDetail === "Cohort (D)" &&
        Object.keys(seqCohortChangeLog).length > 0),
    fetchStudy,
    fetchStudyPlan,
    fetchCohortStudyPlan,
    updatePlan,
    savePlan,
    saveLoading,
    actualsLoading,
    lActualsLoading,
    quickSave,
    resetStudyPlan,
    resetStudyPlanWithCountry,
    updateCountries,
    updateCountryMilestones,
    saveCountries,
    addCountry,
    createNewPlan,
    removeCountry,
    deleteCountryLoading,
    clearChangeLog,
    clearStudyPlan,
    fetchMilestoneDates,
    runSimulation,
    checkSimulationStatus,
    simulationResults,
    fetchSimulationResults,
    fetchSimulationDetailsByMonth,
    fetchSimulationDetailsByMonthNonCumulative,
    fetchSimulationDetailsByWeek,
    fetchSimulationDetailsByWeekNonCumulative,
    fetchSimulationEnrollmentTable,
    fetchSimulationStartupTable,
    fetchSimulationSummaryTable,
    fetchSimulationDurationTable,
    fetchSimulationTimelineTable,
    fetchOverallChart,
    clearSimData,
    fetchCountryChart,
    fetchRegionChart,
    fetchSiteGroupChart,
    fetchCohortChartMonth,
    fetchCohortChartWeek,
    fetchCohortTimelineChart,
    fetchActualsTable,
    loadStudyActuals,
    fetchStudyRemodel,
    updateStudyRemodel,
    setStudyRemodelData,
    setIsCountryUpdate,
    fetchAllCountries,
    allCountries,
    studyActualsData,
    studyRemodelData,
    remodelLoading,
    remodelUpdateLoading,
    STUDY_PLAN_TYPES,
    canEditPlan,
    isCountryUpdate,
    createNewCohortPlan,
    seqCohorts,
    setSeqCohorts,
    seqCohortsStatus,
    validateSequentialCohort,
    setSeqCohortChangeLog,
    isSequential,
    hideEnrollmentDuration,
    seqCohortColumnHeaders,
    setSeqCohortColumnHeaders,

    // saChangeLog,
    // setSAChangeLog,
    // saItems,
    // setSAItems,
    // saDeleteIds,
    // setSADeleteIds,
    // saLoading,
    // setSALoading,
    // saErrors,
    // setSAErrors,

    // saSaveActivationProfiles,

    updateCohortStudyPlan,

    validateSeasonalVariations,
    updateStudyPlan,
  };

  return (
    <StudyContext.Provider value={value}>{children}</StudyContext.Provider>
  );
}
