import AppBar from "@material-ui/core/AppBar";
import Controls from "./controls";
import Divider from "@material-ui/core/Divider";
import React from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useStudyPlan } from "../../../../app/contexts/study.context";

const USA_CODE = "USA";

const CountryTabs = (props) => {
  const history = useHistory();
  const { studyPlan } = useStudyPlan();
  const { countries } = studyPlan;
  const classes = useStyles();
  const tabClasses = useTabStyles();
  const tabsClasses = useTabsStyles();
  const tabLabels = makeTabLabels(countries);

  const handleChange = (index) => {
    history.push(`?country=${index}`);
  };

  return (
    <Container id="country-tabs">
      <Controls />
      <Content>
        <AppBar position="static" color="transparent" classes={classes}>
          <CountriesTabContainer>
            <TabLabel>Countries</TabLabel>
            <TabsWrapper>
              <Tabs
                {...props}
                classes={tabsClasses}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                indicatorColor="primary"
              >
                {tabLabels.map((tab, index) => (
                  <Tab
                    classes={tabClasses}
                    label={tab}
                    key={tab}
                    onClick={() => handleChange(index)}
                    id={`scrollable-auto-tabpanel-${index}`}
                  />
                ))}
              </Tabs>
            </TabsWrapper>
          </CountriesTabContainer>
        </AppBar>
        <Divider />
      </Content>
    </Container>
  );
};

export default CountryTabs;

const Content = styled.div`
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
`;

const CountriesTabContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 48px;
  overflow: hidden;
`;

const TabLabel = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--Primary-color);
  margin-right: 1em;
  white-space: nowrap;
`;

const TabsWrapper = styled.div`
  flex: 1;
  overflow-x: auto;
  overflow-y: hidden;
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-x: hidden;
  scrollbar-width: none;
`;

const Container = styled.div`
  width: 100%;
`;

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none",
    width: "100%",
  },
}));

const useTabStyles = makeStyles(() => ({
  root: {
    minWidth: "78px",
    whiteSpace: "nowrap",
  },
}));

const useTabsStyles = makeStyles(() => ({
  indicator: {
    width: "60px",
  },
  flexContainer: {
    height: "100%",
  },
}));

const makeTabLabels = (countries) =>
  countries
    .sort((a, b) => a.countryCodeISO3.localeCompare(b.countryCodeISO3))
    .map((country) => {
      if (country.countryCodeISO3 === USA_CODE) {
        if (country.siteGroups && country.siteGroups.length > 0) {
          const { siteGroupName } = country.siteGroups[0];
          const labelArr = siteGroupName.replace(/[{()}]/g, "").split(" ");
          return `${labelArr[0]} - ${labelArr[1][0]}`;
        }
        return country.countryCodeISO3;
      }
      return country.countryCodeISO3;
    });