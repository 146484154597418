import {
  ASSESSMENT_REQUIRED_FIELDS,
  NUMBER_FIELDS,
  REQUIRED_FIELDS_ERROR_MESSAGES,
  UNIQUE_FIELDS,
  VISIT_REQUIRED_FIELDS,
} from "../../study.plans/study.plan/soe/constants";
import React, { useEffect, useMemo, useState } from "react";
import { createContext, useContext } from "react";

import API from "../utils/api";
import { ASSESSMENT_TYPES_WITH_SITE_COST } from "../../study.plans/study.plan/soe/assessments.table/table.utils";
import { isNumberNotEmpty } from "../../study.plans/study.plan/utils/helpers";

const ScheduleEventsContext = createContext();

export const useScheduleEvents = () => {
  return useContext(ScheduleEventsContext);
};

export const ScheduleEventsProvider = ({ children }) => {
  const [initialRiskAssessmentTotal, setInitialRiskAssessmentTotal] = useState({
    total: 0,
    label: "Initial Risk Score",
  });
  const [initialRiskAssessments, setInitialRiskAssessments] = useState([]);
  const [updatedSamplingStrategyValues, setUpdatedSamplingStrategyValues] =
    useState({});
  const [visitSampleStrategy, setVisitSampleStrategy] = useState({});
  const [generalChangeLog, setGeneralChangeLog] = useState({});
  const [specialSOEErrorMessages, setSpecialSOEErrorMessages] = useState({});
  const [SOEError, setSOEError] = useState(false);
  const [originalCurrencyData, setOriginalCurrencyData] = useState({});
  const [formattedForecastRFPSummary, setFormattedForecastRFPSummary] =
    useState({ columns: {}, data: [] });
  const [formattedForecastRFPDetail, setFormattedForecastRFPDetail] = useState({
    columns: {},
    data: [],
  });
  const [formattedForecastSummary, setFormattedForecastSummary] = useState({
    countrySummaries: {},
    total: null,
  });
  const [formattedForecastDetail, setFormattedForecastDetail] = useState({
    countrySummaries: {},
    total: null,
    COLUMN_HEADERS: [],
  });
  const [countryCurrencyData, setCountryCurrencyData] = useState({});
  const [SOEForecastDetail, setSOEForecastDetail] = useState([]);
  const [SOEForecastSummary, setSOEForecastSummary] = useState([]);
  const [SOEForecastRFPDetail, setSOEForecastRFPDetail] = useState([]);
  const [SOEForecastRFPSummary, setSOEForecastRFPSummary] = useState([]);
  const [SOEForecastLoading, setSOEForecastLoading] = useState(false);
  const [assessmentNameMappings, setAssessmentNameMappings] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [currentSOE, setCurrentSOE] = useState({});
  const [SOECountries, setSOECountries] = useState([
    { countryCodeISO3: "Study", siteGroupId: 0 },
  ]);
  const [visitsAndAssessmentsMapping, setVisitsAndAssessmentsMapping] =
    useState({});
  const [originalSOEMapping, setOriginalSOEMapping] = useState([]);
  const [totalMappings, setTotalMappings] = useState([]);
  const [
    visitsAndAssessmentsMappingChangeLog,
    setVisitsAndAssessmentsMappingChangeLog,
  ] = useState({});

  const [loadingSOEStudy, setLoadingSOEStudy] = useState(false);
  const [loadingCountrySOE, setLoadingCountrySOE] = useState(false);
  const [savingSOEStudy, setSavingSOEStudy] = useState(false);
  const [currentScheduleOfEvent, setCurrentScheduleOfEvent] = useState({});
  const [scheduleOfEvents, setScheduleOfEvents] = useState([]);
  const [scheduleOfEventsVisits, setScheduleOfEventsVisits] = useState({
    study: [],
  });
  const [scheduleOfEventsAssessments, setScheduleOfEventsAssessments] =
    useState({ study: [] });
  const [saveLoading, setSaveLoading] = useState(false);
  const [scheduleOfEventsVisitsChangeLog, setScheduleOfEventsVisitsChangeLog] =
    useState({});
  const [
    scheduleOfEventsAssessmentsChangeLog,
    setScheduleOfEventsAssessmentsChangeLog,
  ] = useState({});

  const allAssessmentNames = useMemo(
    () => assessmentNameMappings.map((an) => an.assessmentName),
    [assessmentNameMappings],
  );

  // Initialize currency data and store original values
  useEffect(() => {
    const newCurrencyData = {};
    
    SOECountries.forEach(country => {
      if (country.currency || country.exchangeRate) {
        newCurrencyData[country.siteGroupId] = {
          currency: country.currency || '',
          exchangeRate: country.exchangeRate ? country.exchangeRate.toString() : ''
        };
      }
    });
    
    setCountryCurrencyData(newCurrencyData);
    setOriginalCurrencyData(newCurrencyData);
  }, [SOECountries]);

  // Check if currency data has actually changed from original values
  const hasCurrencyChanges = useMemo(() => {
    const currencyKeys = new Set([
      ...Object.keys(originalCurrencyData),
      ...Object.keys(countryCurrencyData)
    ]);

    for (const key of currencyKeys) {
      const original = originalCurrencyData[key] || {};
      const current = countryCurrencyData[key] || {};

      if (original.currency !== current.currency || 
          original.exchangeRate !== current.exchangeRate) {
        return true;
      }
    }
    return false;
  }, [originalCurrencyData, countryCurrencyData]);

  const hasSOEToSave = useMemo(() => {
    return !!(
      Object.keys(scheduleOfEventsVisitsChangeLog).length ||
      Object.keys(scheduleOfEventsAssessmentsChangeLog).length ||
      Object.keys(visitsAndAssessmentsMappingChangeLog).length ||
      Object.keys(generalChangeLog).length ||
      Object.keys(updatedSamplingStrategyValues).length ||
      hasCurrencyChanges // Only true if currency data has actually changed
    );
  }, [
    scheduleOfEventsVisitsChangeLog,
    scheduleOfEventsAssessmentsChangeLog,
    visitsAndAssessmentsMappingChangeLog,
    generalChangeLog,
    updatedSamplingStrategyValues,
    hasCurrencyChanges
  ]);

  const visitKeyWithVisitNameMapping = useMemo(() => {
    let mapping = {};
    scheduleOfEventsVisits["study"].forEach((v) => {
      mapping[v.key || v.scheduleOfEventsVisitId] = v.visitName;
    });

    return mapping;
  }, [scheduleOfEventsVisits]);

  const assessmentKeyWithAssessmentNameMapping = useMemo(() => {
    let mapping = {};
    scheduleOfEventsAssessments["study"].forEach((a) => {
      mapping[a.key || a.scheduleOfEventsAssessmentId] = a.assessmentName;
    });

    return mapping;
  }, [scheduleOfEventsAssessments]);

  const [variablesData, ePrice, CRA, IMV, CDA, CDAStudy, ePriceInfo] =
    useMemo(() => {
      if (!visitSampleStrategy?.length) return [[], [], [], [], [], [], []];
      const lVariables = [];
      const lEPrice = [];
      const lCRA = [];
      const lIMV = [];
      const lCDA = [];
      const lCDAStudy = [];
      const lEPriceInfo = [];
      visitSampleStrategy.forEach((vs) => {
        switch (vs.tableName) {
          case "Variables":
            lVariables.push(vs);
            break;
          case "ePrice (manual entry)":
            lEPrice.push(vs);
            break;
          case "CRA Time - SDR/SDV Time":
            lCRA.push(vs);
            break;
          case "IMV Budgeting":
            lIMV.push(vs);
            break;
          case "CDA Subject Review Time":
            lCDA.push(vs);
            break;
          case "CDA Study Review Time":
            lCDAStudy.push(vs);
            break;
          case "ePrice Info":
            lEPriceInfo.push(vs);
            break;
          default:
            break;
        }
      });

      return [lVariables, lEPrice, lCRA, lIMV, lCDA, lCDAStudy, lEPriceInfo];
    }, [visitSampleStrategy]);
  /*
    The following section adds the logic to update the schedule of events ans assessment mapping table.
    It filters out the assessments that have assessment type value of "Procedure" or "Non Procedure".
    It also adds the new visits to the table on visit table values change.
  */
  useEffect(() => {
    const visitsToUse = scheduleOfEventsVisits.study;
    const av = {};
    scheduleOfEventsAssessments["study"]
      .filter(({ assessmentType }) =>
        ["Procedure", "Non Procedure"].includes(assessmentType),
      )
      .forEach((assessment) => {
        let assessmentKey =
          assessment.key || assessment.scheduleOfEventsAssessmentId;
        let prevValue = visitsAndAssessmentsMapping[assessmentKey];
        let newValue = [
          ...visitsToUse.map((soev) => ({
            scheduleOfEventsVisitId: soev.scheduleOfEventsVisitId,
            key: soev.key,
            value: 0,
            visitName: soev.visitName,
          })),
        ];
        if (prevValue && prevValue?.length === newValue?.length) {
          // let names = visitsToUse.map((v) => v.visitName);
          av[assessmentKey] = prevValue;
          // av[assessmentKey] = prevValue.sort(
          //   (a, b) => names.indexOf(a.visitName) - names.indexOf(b.visitName)
          // );
        } else if (prevValue) {
          let currKeys = newValue.map(
            (cv) => cv.key || cv.scheduleOfEventsAssessmentId,
          );
          av[assessmentKey] =
            prevValue?.length > newValue.length
              ? prevValue.filter((pv) =>
                  currKeys.includes(pv.key || pv.scheduleOfEventsAssessmentId),
                )
              : [...prevValue, ...newValue.slice(prevValue.length)];

          Object.keys(av).forEach((key) => {
            let visitKeys = visitsToUse.map(
              (soev) => soev.key || soev.scheduleOfEventsVisitId,
            );
            av[key] = av[key]?.filter((v) =>
              visitKeys.includes(v.key || v.scheduleOfEventsVisitId),
            );
          });
        } else
          av[assessmentKey] = [
            ...visitsToUse.map((soev) => ({
              scheduleOfEventsVisitId: soev.scheduleOfEventsVisitId,
              key: soev.key,
              value: 0,
              visitName: soev.visitName,
              visitCategory: soev.visitCategory,
            })),
          ];
      });
    setVisitsAndAssessmentsMapping(av);
    // eslint-disable-next-line
  }, [scheduleOfEventsAssessments.study, scheduleOfEventsVisits.study]);

  /*
    The below function is used to identify if two object in a list a have the same value for the key
    passed as parameter.
   */
  const isUnique = (list, key, value, index) => {
    return !list.filter((l, i) => l[key] === value && !(index === i)).length;
  };

  /*
    The below function is used to validate the fields of the schedule of events table.
    It checks if the fields are empty or if the visit name is unique.
    */
  const fieldValid = (key, value, index) => {
    if (NUMBER_FIELDS[key]) {
      return isNumberNotEmpty(value);
    } else if (UNIQUE_FIELDS[key]) {
      switch (key) {
        case "visitName":
          return !value
            ? false
            : isUnique(
                scheduleOfEventsVisits["study"],
                "visitName",
                value,
                index,
              );
        case "assessmentName":
          return !value
            ? false
            : isUnique(
                scheduleOfEventsAssessments["study"],
                "assessmentName",
                value,
                index,
              );
        default:
          break;
      }
    } else if (!value) return false;
    return true;
  };
  /*
    The below function is used to validate the schedule of events table.
  */
  const checkSOEValid = () => {
    let valid = true;
    let lErrorMessages = {};
    scheduleOfEventsAssessments["study"].forEach((v, index) => {
      const keys = Object.keys(v);
      keys.forEach((key) => {
        if (
          ASSESSMENT_REQUIRED_FIELDS[key] &&
          !fieldValid(key, v[key], index)
        ) {
          lErrorMessages = {
            ...lErrorMessages,
            [key]: {
              message: REQUIRED_FIELDS_ERROR_MESSAGES[key],
              count: lErrorMessages[key]?.count
                ? lErrorMessages[key]?.count + 1
                : 1,
            },
          };
          setErrorMessages(lErrorMessages);
          valid = false;
        }
      });
    });

    scheduleOfEventsVisits["study"].forEach((v, index) => {
      const keys = Object.keys(v);
      keys.forEach((key) => {
        if (key === "visitStudyDay" && v.visitCategory === "Unscheduled")
          return;
        if (VISIT_REQUIRED_FIELDS[key] && !fieldValid(key, v[key], index)) {
          lErrorMessages = {
            ...lErrorMessages,
            [key]: {
              message:
                typeof REQUIRED_FIELDS_ERROR_MESSAGES[key] === "function"
                  ? REQUIRED_FIELDS_ERROR_MESSAGES[key](
                      currentSOE.visitIntervalType || "Day",
                    )
                  : REQUIRED_FIELDS_ERROR_MESSAGES[key],
              count: lErrorMessages[key]?.count
                ? lErrorMessages[key]?.count + 1
                : 1,
            },
          };
          setErrorMessages(lErrorMessages);
          valid = false;
        }
      });
    });
    valid = valid && !Object.keys(specialSOEErrorMessages).length;
    return [valid, { ...lErrorMessages, ...specialSOEErrorMessages }];
  };

  const fetchRiskAssessments = async (scheduleOfEventsId) => {
    setLoadingSOEStudy(true);
    let res = await API.getRiskAssessments(scheduleOfEventsId).catch((err) =>
      console.log(err),
    );
    if (res?.data) setInitialRiskAssessments(res.data);
    setLoadingSOEStudy(false);
    return res?.data;
  };

  const fetchVisitSampleStrategy = async (scheduleOfEventsId) => {
    setLoadingSOEStudy(true);
    let res = await API.getVisitSampleStrategy(scheduleOfEventsId).catch(
      (err) => console.log(err),
    );
    if (res?.data) setVisitSampleStrategy(res.data);
    setLoadingSOEStudy(false);
    return res?.data;
  };
  /*
    The function below is used to fetch the assessment names used for searching in the fuzzy search input.
  */
  const fetchAssessmentNames = async () => {
    if (assessmentNameMappings.length) return assessmentNameMappings;
    setLoadingSOEStudy(true);
    let res = await API.getAssessmentNames().catch((err) => console.log(err));
    if (res?.data) setAssessmentNameMappings(res?.data);
    setLoadingSOEStudy(false);
    return res?.data;
  };

  /*
   * @param {string} scheduleId - the schedule id
   * @returns {object} - forecast detail values
   */
  const fetchSOEForecastDetail = async (scheduleId) => {
    setSOEForecastLoading(true);
    let res = await API.getSOEForecastDetail(scheduleId).catch((err) =>
      console.log(err),
    );
    if (res?.data) setSOEForecastDetail(res?.data);
    setSOEForecastLoading(false);
    return res?.data;
  };

  /*
   * @param {string} scheduleId - the schedule id
   * @returns {object} - forecast summary values
   */
  const fetchSOEForecastSummary = async (scheduleId) => {
    setSOEForecastLoading(true);
    let res = await API.getSOEForecastSummary(scheduleId).catch((err) =>
      console.log(err),
    );
    if (res?.data) setSOEForecastSummary(res?.data);
    setSOEForecastLoading(false);
    return res?.data;
  };

  /*
   * @param {string} scheduleId - the schedule id
   * @returns {object} - forecast rfp detail values
   */
  const fetchSOEForecastRFPDetail = async (scheduleId) => {
    setSOEForecastLoading(true);
    let res = await API.getSOEForecastRFPDetail(scheduleId).catch((err) =>
      console.log(err),
    );
    if (res?.data) setSOEForecastRFPDetail(res?.data);
    setSOEForecastLoading(false);
    return res?.data;
  };

  /*
   * @param {string} scheduleId - the schedule id
   * @returns {object} - forecast rfp summary values
   */
  const fetchSOEForecastRFPSummary = async (scheduleId) => {
    setSOEForecastLoading(true);
    let res = await API.getSOEForecastRFPSummary(scheduleId).catch((err) =>
      console.log(err),
    );
    if (res?.data) setSOEForecastRFPSummary(res?.data);
    setSOEForecastLoading(false);
    return res?.data;
  };

  /*
   * @param {string} id - the schedule id
   * @returns {object} - a single schedule of event
   */
  const fetchASingleSOE = async (id) => {
    setSavingSOEStudy(true);
    let res = await API.getSOE(id).catch((err) => console.log(err));
    setCurrentSOE(res?.data ? res.data[0] : {});
    setSavingSOEStudy(false);
    return res;
  };

  /*
   * @param {string} id - the study id
   * @returns {object} - a list of schedule of events
   */
  const fetchSOEStudy = async (id) => {
    setLoadingSOEStudy(true);
    let res = await API.getSOEs(id).catch((err) => console.log(err));
    if (res) {
      setScheduleOfEvents(res.data);
      setLoadingSOEStudy(false);
    }
  };

  /*
   * @param {string} id - the schedule id
   * @returns {object} - a list of countries for the schedule of events
   */
  const fetchSOECountries = async (id) => {
    setLoadingSOEStudy(true);
    let res = await API.getSOECountries(id).catch((err) => console.log(err));
    if (res && res.data) {
      setSOECountries([SOECountries[0], ...res.data]);
    }
    setLoadingSOEStudy(false);
  };

  /*
   * @param {string} scheduleId - the schedule id
   * @param {string} countryId - the country id
   * @returns {object} - a list of visits for the specified country
   */
  const fetchSOECountryVisits = async (scheduleId, countryId, siteId) => {
    let res = await API.getSOECountryVisits(
      scheduleId,
      countryId,
      siteId,
    ).catch((err) => console.log(err));

    if (res) {
      setScheduleOfEventsVisits({
        ...scheduleOfEventsVisits,
        [`${countryId}-${siteId}`]: res.data,
      });
      return res.data;
    }

    return null;
  };

  /**
   *
   * @param {string} scheduleId - the schedule id
   * @param {string} countryId - the country id
   * @returns {object} - a list of assessments for the specified country
   */
  const fetchSOECountryAssessments = async (scheduleId, countryId, siteId) => {
    let res = await API.getSOECountryAssessments(
      scheduleId,
      countryId,
      siteId,
    ).catch((err) => console.log(err));

    if (res) {
      setScheduleOfEventsAssessments({
        ...scheduleOfEventsAssessments,
        [`${countryId}-${siteId}`]: res.data,
      });
      return res.data;
    }

    return null;
  };

  /**
   *
   * @param {string} scheduleId - the schedule id
   * @param {string} studyId - the study id
   * @param {boolean} isCountry - a boolean to indicate if the request is for a country
   * @param {string} countryId - the country id
   * @returns {object} - a list of visits for the specified country/soe
   */
  const fetchSOEVisits = async (
    scheduleId,
    studyId,
    isCountry,
    countryId,
    siteId,
    withoutLoading,
  ) => {
    let res;
    if (isCountry && countryId) {
      setLoadingCountrySOE(true);
      res = await API.getSOECountryVisits(scheduleId, countryId, siteId).catch(
        (err) => console.log(err),
      );
    } else {
      if (!withoutLoading) setLoadingSOEStudy(true);
      res = await API.getSOEStudyVisits(scheduleId).catch((err) =>
        console.log(err),
      );
    }
    if (res) {
      if (isCountry && countryId) {
        setScheduleOfEventsVisits({
          ...scheduleOfEventsVisits,
          [`${countryId}-${siteId}`]: res.data,
        });
      } else {
        if (res.data.length === 0) {
          setScheduleOfEventsVisits({
            study: [],
          });
        } else
          setScheduleOfEventsVisits({
            study: res.data.map((d) => ({
              scheduleOfEventsId: d.scheduleOfEventsId,
              studyId: parseInt(studyId || "0"),
              visitName: d.visitName,
              visitStudyDay: d.visitStudyDay,
              scheduleOfEventsVisitId: d.scheduleOfEventsVisitId,
              visitCost: d.visitCost,
              commentsVisit: d.commentsVisit,
              commentsCost: d.commentsCost,
              commentsClinicalStrategy: d.commentsClinicalStrategy,
              commentsDataManagement: d.commentsDataManagement,
              visitOverheadPct: d.visitOverheadPct,
              visitOverhead: d.visitOverhead,
              visitCategory: d.visitCategory,
              visitVarianceType: d.visitVarianceType,
              visitVariance: d.visitVariance,
              visitIntervalType: d.visitIntervalType,
              miEstSDRSDV: d.miEstSDRSDV,
              miSampleVisit: d.miSampleVisit,
              miSuggestedOnSiteSDRSDV: d.miSuggestedOnSiteSDRSDV,
              miSuggestedCDAReview: d.miSuggestedCDAReview,
              miEstCDAReportFrequency: d.miEstCDAReportFrequency,
              dmUniqueCRFs: d.dmUniqueCRFs,
              dmNonUniqueCRFs: d.dmNonUniqueCRFs,
              dmIntegratedEpro: d.dmIntegratedEpro,
              dmPaperEpro: d.dmPaperEpro,
              dmCentralLab: d.dmCentralLab,
              dmLocalLab: d.dmLocalLab,
            })),
          });
      }
      if (isCountry && countryId) setLoadingCountrySOE(false);
      else setLoadingSOEStudy(false);
    }
  };

  /**
   *
   * @param {string} scheduleId - the schedule id
   * @param {string} id - the study id
   * @param {boolean} isCountry - a boolean to indicate if the request is for a country
   * @param {string} countryId - the country id
   * @returns {object} - a list of assessments for the specified country/soe
   */
  const fetchSOEAssessments = async (
    scheduleId,
    id,
    isCountry,
    countryId,
    siteId = 1,
    withoutLoading,
  ) => {
    let res;
    if (isCountry && countryId) {
      setLoadingCountrySOE(true);
      res = await API.getSOECountryAssessments(
        scheduleId,
        countryId,
        siteId,
      ).catch((err) => console.log(err));
    } else {
      if (!withoutLoading) setLoadingSOEStudy(true);
      res = await API.getSOEStudyAssessments(scheduleId).catch((err) =>
        console.log(err),
      );
    }
    if (res) {
      if (isCountry && countryId) {
        setScheduleOfEventsAssessments({
          ...scheduleOfEventsAssessments,
          [`${countryId}-${siteId}`]: res.data,
        });
      } else {
        if (res.data.length === 0)
          setScheduleOfEventsAssessments({
            study: [],
          });
        else {
          const lAssessmentNameMappings = await fetchAssessmentNames();
          setScheduleOfEventsAssessments({
            study: res.data.map((d) => {
              const localAssessmentNames = lAssessmentNameMappings
                .filter((an) =>
                  ASSESSMENT_TYPES_WITH_SITE_COST.includes(d.assessmentType)
                    ? an.assessmentType === "Site Costs"
                    : an.assessmentType === d.assessmentType,
                )
                .map((a) => a.assessmentName);
              return {
                assessmentName: d.assessmentName,
                assessmentNameMapping: d.assessmentNameMapping,
                assessmentCost: d.assessmentCost,
                commentsAssessment: d.commentsAssessment,
                commentsCost: d.commentsCost,
                commentsClinicalStrategy: d.commentsClinicalStrategy,
                commentsDataManagement: d.commentsDataManagement,
                overhead: d.overhead,
                exclude: d.exclude,
                ipAdministered: d.ipAdministered,
                assessmentSortOrder: d.assessmentSortOrder,
                scheduleOfEventsId: d.scheduleOfEventsId,
                studyId: parseInt(id || "0"),
                scheduleOfEventsAssessmentId: d.scheduleOfEventsAssessmentId,
                assessmentType: d.assessmentType,
                assessmentNames: localAssessmentNames,
              };
            }),
          });
        }
      }
      if (isCountry && countryId) setLoadingCountrySOE(false);
      else setLoadingSOEStudy(false);
    }
  };

  /**
   *
   * @param {string} scheduleId
   * returns {object} - a list of mappings for the specified schedule
   */
  const fetchSOEMapping = async (scheduleId, withoutLoading) => {
    if (!withoutLoading) setLoadingSOEStudy(true);
    let res = await API.getSOEMapping(scheduleId).catch((err) =>
      console.log(err),
    );
    if (res) {
      let newSOEMapping = {};
      setOriginalSOEMapping(res.data);
      res.data.forEach((mapping) => {
        if (newSOEMapping[mapping.scheduleOfEventsAssessmentId]) {
          newSOEMapping[mapping.scheduleOfEventsAssessmentId].push({
            value: mapping.mappingAssessmentVisit === 0 ? false : true,
            multiplier: mapping.multiplier ?? null,
            uniqueNonUniqueForm: mapping.uniqueNonUniqueForm || null,
            scheduleOfEventsVisitId: mapping.scheduleOfEventsVisitId,
            visitName: mapping.visitName,
            key: "",
          });
        } else {
          newSOEMapping[mapping.scheduleOfEventsAssessmentId] = [
            {
              value: mapping.mappingAssessmentVisit === 0 ? false : true,
              uniqueNonUniqueForm: mapping.uniqueNonUniqueForm || null,
              multiplier: mapping.multiplier ?? null,
              scheduleOfEventsVisitId: mapping.scheduleOfEventsVisitId,
              visitName: mapping.visitName,
              key: "",
            },
          ];
        }
      });
      setVisitsAndAssessmentsMapping(newSOEMapping);

      setLoadingSOEStudy(false);
    }
  };

  /**
   *
   * @param {object} newSOEPlan - the new schedule of event
   * @param {function} redirect - the function to redirect to the next page
   * creates a new schedule of event
   */
  const createNewSOEPlan = async (newSOEPlan, redirect) => {
    setLoadingSOEStudy(true);
    setSaveLoading(true);
    let res = await API.createNewSOEPlan(newSOEPlan).catch((err) => {
      if (!err.response) {
        setLoadingSOEStudy(false);
        setSaveLoading(false);
      }
    });
    if (res) {
      setLoadingSOEStudy(false);
      setSaveLoading(false);
      if (redirect) redirect();
    }
  };

  /**
   * updates the schedule of event
   * @param {object} newSOEPlan - the new schedule of event
   * @param {function} redirect - the function to redirect to the next page
   */
  const updateSOE = async (newSOEPlan, redirect) => {
    setSavingSOEStudy(true);
    let res = await API.updateSOE(newSOEPlan);
    if (res) {
      setSavingSOEStudy(false);
      if (redirect) redirect();
    }
  };

  /**
   * deletes the schedule of event
   * @param {string} scheduleOfEventsId - the schedule of event id
   * @param {string} studyId - the study id
   * returns {boolean} - a boolean to indicate if the delete was successful
   */
  const deleteSOE = async (scheduleOfEventsId, studyId) => {
    setSavingSOEStudy(true);
    let res = await API.deleteSOE(scheduleOfEventsId, studyId);
    if (res) {
      setSavingSOEStudy(false);
      if (res.data[0].Id === -1) {
        setSOEError(true);
        return false;
      }
      return true;
    }
    return false;
  };

  /**
   * updates the schedule of event visits for a country/soe
   * @param {string} studyId - the study id
   * @param {string} siteGroupId - the site group id
   * @param {function} redirect - the function to redirect to the next page
   */
  const updateSOECountryVisits = async (
    studyId,
    siteGroupId,
    siteId = 1,
    redirect,
  ) => {
    setSaveLoading(true);
    const soeVisitsToSave = scheduleOfEventsVisits[
      `${siteGroupId}-${siteId}`
    ]?.map((soev) => {
      return {
        studyId,
        scheduleOfEventsId: soev.scheduleOfEventsId,
        scheduleOfEventsVisitId: soev.scheduleOfEventsVisitId,
        siteGroupId: soev.siteGroupId,
        visitCost: soev.visitCost,
        visitOverheadPct: soev.visitOverheadPct,
        visitCostTotal: soev.visitCostTotal,
        siteId,
      };
    });
    const res = await API.createSOECountryVisits(
      soeVisitsToSave,
      studyId,
    ).catch((err) => {
      if (!err.response) {
        setSaveLoading(false);
      }
    });

    if (res) {
      setSaveLoading(false);
      if (redirect) redirect();
    }
  };

  /**
   * creates the schedule of event visits for a country/soe
   * @param {string} studyId
   * @param {function} redirect - the function to redirect to the next page
   * @returns {object} - a list of visits for the specified soe
   */
  const createSOEVisits = async (studyId, redirect) => {
    setSaveLoading(true);
    const scheduleOfEventsVisitsValuesToSave = scheduleOfEventsVisits[
      "study"
    ].map((soev, index) => {
      if (soev.scheduleOfEventsVisitId)
        return { ...soev, visitSortOrder: index };
      else
        return {
          scheduleOfEventsId: soev.scheduleOfEventsId,
          studyId: soev.studyId,
          visitName: soev.visitName,
          visitStudyDay: soev.visitStudyDay,
          visitCost: soev.visitCost,
          visitCostTotal: soev.visitCostTotal,
          commentsVisit: soev.commentsVisit,
          commentsCost: soev.commentsCost,
          commentsClinicalStrategy: soev.commentsClinicalStrategy,
          commentsDataManagement: soev.commentsDataManagement,
          visitOverheadPct: soev.visitOverheadPct,
          visitOverhead: soev.visitOverhead,
          visitCategory: soev.visitCategory,
          visitVarianceType: soev.visitVarianceType,
          visitVariance: soev.visitVariance,
          visitSortOrder: index,
          miEstSDRSDV: soev.miEstSDRSDV,
          miSampleVisit: soev.miSampleVisit,
          miSuggestedOnSiteSDRSDV: soev.miSuggestedOnSiteSDRSDV,
          miSuggestedCDAReview: soev.miSuggestedCDAReview,
          miEstCDAReportFrequency: soev.miEstCDAReportFrequency,
          dmUniqueCRFs: soev.dmUniqueCRFs,
          dmNonUniqueCRFs: soev.dmNonUniqueCRFs,
          dmIntegratedEpro: soev.dmIntegratedEpro,
          dmPaperEpro: soev.dmPaperEpro,
          dmCentralLab: soev.dmCentralLab,
          dmLocalLab: soev.dmLocalLab,
        };
    });
    let res = await API.createSOEVisits(
      scheduleOfEventsVisitsValuesToSave.length === 0
        ? { scheduleOfEventsId: currentSOE.scheduleOfEventsId }
        : scheduleOfEventsVisitsValuesToSave,
      studyId,
    ).catch((err) => {
      if (!err.response) {
        setSaveLoading(false);
      }
    });
    if (res) {
      setSaveLoading(false);
      if (redirect) redirect();
    }

    return res;
  };
  
    // Add handlers for currency and exchange rate changes
    const handleCurrencyChange = (countryId, value) => {
      const formattedValue = value.replace(/[^A-Za-z]/g, '').toUpperCase().slice(0, 3);
      setCountryCurrencyData(prev => ({
        ...prev,
        [countryId]: {
          ...prev[countryId],
          currency: formattedValue
        }
      }));
    };
  
    const handleExchangeRateChange = (countryId, value) => {
      const regex = /^\d*\.?\d{0,4}$/;
      if (regex.test(value) && (value === '' || parseFloat(value) >= 0)) {
        setCountryCurrencyData(prev => ({
          ...prev,
          [countryId]: {
            ...prev[countryId],
            exchangeRate: value
          }
        }));
      }
    };
  // Add new API function:
  const saveSOECurrency = async (studyId, currencyData) => {
    setLoadingSOEStudy(true);
    try {
      const response = await API.saveSOECurrencyData(studyId, currencyData);
      setCountryCurrencyData(currencyData);
      return true;
    } catch (error) {
      console.error('Currency Save - Error:', error);
      return false;
    } finally {
      setLoadingSOEStudy(false);
    }
  };

  /**
   * updates the schedule of event assessments for a country/soe
   * @param {string} studyId - the study id
   * @param {string} siteGroupId - the site group id
   * @param {function} redirect - the function to redirect to the next page
   */
  const updateSOECountryAssessments = async (
    studyId,
    siteGroupId,
    siteId = 1,
    redirect,
  ) => {
    setSaveLoading(true);
    const soeAssessmentsToSave = scheduleOfEventsAssessments[
      [`${siteGroupId}-${siteId}`]
    ]?.map((soea) => {
      return {
        studyId,
        scheduleOfEventsId: soea.scheduleOfEventsId,
        scheduleOfEventsAssessmentId: soea.scheduleOfEventsAssessmentId,
        siteGroupId: soea.siteGroupId,
        siteId,
        assessmentCost: soea.assessmentCost,
      };
    });
    const res = await API.createSOECountryAssessments(
      soeAssessmentsToSave,
      studyId,
    ).catch((err) => {
      if (!err.response) {
        setSaveLoading(false);
      }
    });

    if (res) {
      setSaveLoading(false);
      if (redirect) redirect();
    }
  };

  /**
   * creates the schedule of event assessments for a country/soe
   * @param {string} studyId - the study id
   * @param {function} redirect - the function to redirect to the next page
   * @returns {object} - a list of assessments for the specified soe
   */
  const createSOEAssessments = async (studyId, redirect) => {
    setSaveLoading(true);
    const scheduleOfEventsAssessmentsValuesToSave = scheduleOfEventsAssessments[
      "study"
    ].map((soea, index) => {
      if (soea.scheduleOfEventsAssessmentId)
        return { ...soea, assessmentSortOrder: index };
      else
        return {
          scheduleOfEventsId: soea.scheduleOfEventsId,
          studyId: soea.studyId,
          assessmentName: soea.assessmentName,
          assessmentNameMapping: soea.assessmentNameMapping,
          assessmentCost: soea.assessmentCost,
          commentsAssessment: soea.commentsAssessment,
          commentsCost: soea.commentsCost,
          commentsClinicalStrategy: soea.commentsClinicalStrategy,
          commentsDataManagement: soea.commentsDataManagement,
          overhead: soea.overhead,
          exclude: soea.exclude,
          ipAdministered: soea.ipAdministered,
          assessmentType: soea.assessmentType,
          assessmentSortOrder: index,
        };
    });
    let res = await API.createSOEAssessments(
      scheduleOfEventsAssessmentsValuesToSave.length === 0
        ? { scheduleOfEventsId: currentSOE.scheduleOfEventsId }
        : scheduleOfEventsAssessmentsValuesToSave,
      studyId,
    ).catch((err) => {
      if (!err.response) {
        setSaveLoading(false);
      }
    });
    if (res) {
      setSaveLoading(false);
      if (redirect) redirect();
    }

    return res;
  };

  /**
   * creates visit assessment mapping for a schedule of event
   * @param {string} studyId - the study id
   * @param {string} scheduleOfEventsId - the schedule of event id
   * @param {object} SOEMapping - the schedule of event mapping
   * @param {function} redirect - the function to redirect to the next page
   * @returns {object} - a list of mappings for the specified soe
   */
  const createVisitAndAssessmentMapping = async (
    studyId,
    scheduleOfEventsId,
    SOEMapping,
    redirect,
  ) => {
    setSaveLoading(true);
    const soeAssessmentVisitMapping = [];
    Object.keys(SOEMapping).forEach((key) => {
      SOEMapping[key].forEach((vaam) => {
        soeAssessmentVisitMapping.push({
          studyId: parseInt(studyId || "0"),
          scheduleOfEventsId: parseInt(scheduleOfEventsId || "0"),
          scheduleOfEventsAssessmentId: key ? parseInt(key + "") : 0,
          scheduleOfEventsVisitId: vaam.scheduleOfEventsVisitId
            ? parseInt(vaam.scheduleOfEventsVisitId + "")
            : 0,
          mappingAssessmentVisit: vaam.value ? 1 : 0,
          multiplier: vaam.value && vaam.multiplier ? +vaam.multiplier : null,
          uniqueNonUniqueForm: vaam.uniqueNonUniqueForm || null,
        });
      });
    });

    let res = await API.createSOEAssessmentVisitMapping(
      soeAssessmentVisitMapping,
      studyId,
    ).catch((err) => {
      if (!err.response) {
        setSaveLoading(false);
      }
    });

    if (res) {
      setSaveLoading(false);
      if (redirect) redirect();
    }

    return res;
  };

  /**
   * updates visit assessment mapping for a schedule of event
   * @param {Array} newVisits - a list of new visits
   * @param {Object} SOEMapping - the schedule of event mapping
   * @returns {Object} - a list of mappings for the specified soe
   */
  const updateSOEMappingForVisits = async (newVisits, SOEMapping) => {
    let newSOEMapping = {};
    let visitNames = newVisits.map((v) => v.visitName);
    let visitNameToKeysMap = {};
    newVisits.forEach((v) => {
      visitNameToKeysMap[v.visitName] = v.scheduleOfEventsVisitId;
    });
    Object.keys(SOEMapping).forEach((key) => {
      newSOEMapping[key] = SOEMapping[key].map((vaam) => {
        if (
          visitNames.includes(
            visitKeyWithVisitNameMapping[
              vaam.key || vaam.scheduleOfEventsVisitId
            ],
          )
        )
          return {
            ...vaam,
            scheduleOfEventsVisitId:
              visitNameToKeysMap[
                visitKeyWithVisitNameMapping[
                  vaam.key || vaam.scheduleOfEventsVisitId
                ]
              ],
            key: undefined,
            visitName:
              visitKeyWithVisitNameMapping[
                vaam.key || vaam.scheduleOfEventsVisitId
              ],
          };

        return vaam;
      });
    });

    setVisitsAndAssessmentsMapping(newSOEMapping);
    return newSOEMapping;
  };

  /**
   * updates visit assessment mapping for a schedule of event
   * @param {*} newAssessments
   * @param {*} SOEMapping
   * @returns {Object} - a list of mappings for the specified soe
   */
  const updateSOEMappingForAssessments = async (newAssessments, SOEMapping) => {
    let newSOEMapping = {};
    let assessmentNames = newAssessments.map((a) => a.assessmentName);
    let assessmentNameToKeysMap = {};
    newAssessments.forEach((a) => {
      assessmentNameToKeysMap[a.assessmentName] =
        a.scheduleOfEventsAssessmentId;
    });
    Object.keys(SOEMapping).forEach((key) => {
      let asName = assessmentKeyWithAssessmentNameMapping[key];
      if (assessmentNames.includes(asName)) {
        newSOEMapping[assessmentNameToKeysMap[asName]] = SOEMapping[key];
      } else {
        newSOEMapping[key] = visitKeyWithVisitNameMapping[key];
      }
    });

    setVisitsAndAssessmentsMapping(newSOEMapping);
    return newSOEMapping;
  };

  const updateInitialRiskAssessments = async (studyId) => {
    const updatedRiskAssessments = initialRiskAssessments.map((ra) => ({
      studyId: +studyId,
      scheduleOfEventsId: ra.scheduleOfEventsId,
      scheduleOfEventsRiskAssessmentId: ra.scheduleOfEventsRiskAssessmentId,
      riskAssessmentStudySpecifics: ra.riskAssessmentStudySpecifics,
      riskAssessmentRiskScore: ra.riskAssessmentRiskScore
        ? +ra.riskAssessmentRiskScore
        : ra.riskAssessmentRiskScore,
    }));
    return await API.updateRiskAssessment(studyId, updatedRiskAssessments);
  };

  const updateVisitSampleStrategy = async (studyId) => {
    const updatedVisitSampleStrategy = Object.keys(
      updatedSamplingStrategyValues,
    ).map((vss) => {
      const [scheduleOfEventsId, tableSort, rowSort] = vss.split("-");
      return {
        studyId: +studyId,
        scheduleOfEventsId: +scheduleOfEventsId,
        tableSort: +tableSort,
        rowSort: +rowSort,
        notes: updatedSamplingStrategyValues[vss].notes,
      };
    });
    return await API.updateVisitSampleStrategy(
      studyId,
      updatedVisitSampleStrategy,
    );
  };

  /**
   * handles saving of the schedule of event mapping, visits and assessments
   * @param {string} studyId - the study id
   * @param {string} scheduleOfEventsId - the schedule of event id
   * @param {function} callback - the function to call after saving
   * @param {function} redirect - the function to redirect to the next page
   */
  const handleSOESave = async (
    studyId,
    scheduleOfEventsId,
    callback,
    redirect,
  ) => {
    
    setLoadingSOEStudy(true);
    let SOEMapping = { ...visitsAndAssessmentsMapping };

    if (hasCurrencyChanges) {
      const currencyData = Object.entries(countryCurrencyData).map(([siteGroupId, data]) => ({
        scheduleOfEventsId,
        siteGroupId: parseInt(siteGroupId),
        currency: data.currency,
        exchangeRate: parseFloat(data.exchangeRate)
      }));
      
      await saveSOECurrency(studyId, currencyData);
      setOriginalCurrencyData(countryCurrencyData); // Update original values after successful save
    }


    if (!!Object.keys(scheduleOfEventsVisitsChangeLog).length) {
      let keys = Object.keys(scheduleOfEventsVisitsChangeLog);
      await Promise.all(
        keys.map(async (key) => {
          if (key === "study") {
            const res = await createSOEVisits(studyId);
            if (res) {
              SOEMapping = await updateSOEMappingForVisits(
                res.data,
                SOEMapping,
              );
            }
          } else {
            await updateSOECountryVisits(studyId, ...key.split("-"));
          }
        }),
      );
      setScheduleOfEventsVisitsChangeLog({});
    }
    if (!!Object.keys(scheduleOfEventsAssessmentsChangeLog).length) {
      const keys = Object.keys(scheduleOfEventsAssessmentsChangeLog);
      await Promise.all(
        keys.map(async (key) => {
          if (key === "study") {
            const res = await createSOEAssessments(studyId);
            if (res) {
              SOEMapping = await updateSOEMappingForAssessments(
                res.data,
                SOEMapping,
              );
            }
          } else {
            await updateSOECountryAssessments(studyId, ...key.split("-"));
          }
        }),
      );
      setScheduleOfEventsAssessmentsChangeLog({});
    }

    if (!!Object.keys(visitsAndAssessmentsMappingChangeLog).length) {
      await createVisitAndAssessmentMapping(
        studyId,
        scheduleOfEventsId,
        SOEMapping,
      );
      setVisitsAndAssessmentsMappingChangeLog({});
    }

    if (Object.keys(generalChangeLog).includes("initialRiskAssessments")) {
      await updateInitialRiskAssessments(studyId);
      setGeneralChangeLog({});
    }

    if (Object.keys(updatedSamplingStrategyValues).length) {
      await updateVisitSampleStrategy(studyId);
      setUpdatedSamplingStrategyValues({});
    }

    setCountryCurrencyData({});
    setVisitsAndAssessmentsMappingChangeLog({});
    setGeneralChangeLog({});
    setUpdatedSamplingStrategyValues({});
    
    callback && (await callback());
    redirect && redirect();
  };

  return (
    <ScheduleEventsContext.Provider
      value={{
        scheduleOfEvents,
        fetchSOEStudy,
        loadingSOEStudy,
        createNewSOEPlan,
        saveLoading,
        fetchSOEVisits,
        scheduleOfEventsVisits,
        fetchSOEAssessments,
        scheduleOfEventsAssessments,
        setScheduleOfEventsVisitsChangeLog,
        scheduleOfEventsVisitsChangeLog,
        setScheduleOfEventsVisits,
        currentScheduleOfEvent,
        setCurrentScheduleOfEvent,
        setScheduleOfEventsAssessmentsChangeLog,
        scheduleOfEventsAssessmentsChangeLog,
        setScheduleOfEventsAssessments,
        createSOEVisits,
        hasSOEToSave,
        handleSOESave,
        updateSOE,
        visitsAndAssessmentsMapping,
        setVisitsAndAssessmentsMapping,
        setVisitsAndAssessmentsMappingChangeLog,
        fetchASingleSOE,
        fetchSOEMapping,
        checkSOEValid,
        savingSOEStudy,
        setSavingSOEStudy,
        fetchSOECountries,
        SOECountries,
        setSOECountries,
        loadingCountrySOE,
        currentSOE,
        errorMessages,
        fetchAssessmentNames,
        assessmentNames: assessmentNameMappings,
        allAssessmentNames,
        fetchSOECountryVisits,
        fetchSOECountryAssessments,
        fetchSOEForecastDetail,
        fetchSOEForecastSummary,
        SOEForecastLoading,
        SOEForecastSummary,
        SOEForecastDetail,
        formattedForecastSummary,
        setFormattedForecastSummary,
        formattedForecastDetail,
        setFormattedForecastDetail,
        fetchSOEForecastRFPDetail,
        fetchSOEForecastRFPSummary,
        SOEForecastRFPSummary,
        SOEForecastRFPDetail,
        formattedForecastRFPSummary,
        setFormattedForecastRFPSummary,
        formattedForecastRFPDetail,
        setFormattedForecastRFPDetail,
        isUnique,
        deleteSOE,
        SOEError,
        setSOEError,
        originalSOEMapping,
        totalMappings,
        setTotalMappings,
        specialSOEErrorMessages,
        setSpecialSOEErrorMessages,
        fetchRiskAssessments,
        initialRiskAssessments,
        setInitialRiskAssessments,
        setGeneralChangeLog,
        initialRiskAssessmentTotal,
        setInitialRiskAssessmentTotal,
        fetchVisitSampleStrategy,
        visitSampleStrategy,
        variablesData,
        ePrice,
        CRA,
        IMV,
        CDA,
        CDAStudy,
        ePriceInfo,
        setVisitSampleStrategy,
        setUpdatedSamplingStrategyValues,
        updatedSamplingStrategyValues,
        countryCurrencyData,
        setCountryCurrencyData,
        saveSOECurrency,
        handleCurrencyChange,
        handleExchangeRateChange,
      }}
    >
      {children}
    </ScheduleEventsContext.Provider>
  );
};
