import React, { useEffect, useMemo } from "react";
import Alert from "@material-ui/lab/Alert";
import { SectionHeader } from "../../../../../app/components";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import ActivationProfilesTable from "./activationProfilesTable";
import SeparateContainer from "../../../../../app/components/separate.Container";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const TOTAL_COLUMNS = 11;

const createEmptyProfile = (index) => ({
  siteActivationProfileId: `empty-${index}`,
  siteActivationLabel: "",
  siteActivationProfilePct: null,
  siteActivationProfileDays: null,
});

const SiteGroupActivationProfiles = ({ country, siteGroup, setHasErrors }) => {
  const {
    siteGroupId,
    activationProfiles: originalProfiles,
    milestones,
  } = siteGroup;
  const { countryId } = country;

  const FSIV = milestones.find((m) => m.milestoneName === "DURATION_TO_FSIV");
  const { studyPlan, STUDY_PLAN_TYPES } = useStudyPlan();
  const { studyPlanType } = studyPlan;
  const { SCENARIO_A, SCENARIO_B } = STUDY_PLAN_TYPES;
  // Ensure we always have 11 profiles
  const activationProfiles = useMemo(() => {
    const profiles = [...originalProfiles];
    while (profiles.length < TOTAL_COLUMNS) {
      profiles.push(createEmptyProfile(profiles.length));
    }
    return profiles;
  }, [originalProfiles]);

  const filterNonEmptyValues = (arr) =>
    arr.filter((value) => value !== undefined && value !== null && value !== "");

  const siteCountArray = filterNonEmptyValues(
    activationProfiles?.map((item) => item.siteActivationProfilePct) || []
  );
  const daysArray = filterNonEmptyValues(
    activationProfiles?.map((item) => item.siteActivationProfileDays) || []
  );

  // Validation functions
  const isPositiveInteger = (value) => {
    return Number.isInteger(value) && value > 0;
  };

  const isStrictlyIncreasing = (arr) => {
    if (arr.length < 2) return true;
    for (let i = 1; i < arr.length; i++) {
      if (arr[i] <= arr[i - 1]) return false;
    }
    return true;
  };

  // Validation checks
  const areSitesPositiveIntegers = siteCountArray.every(isPositiveInteger);
  const areSitesIncreasing = isStrictlyIncreasing(siteCountArray);
  const areDaysPositive = daysArray.every(value => value >= 0);
  const areDaysIncreasing = isStrictlyIncreasing(daysArray);

  const errorsArray = useMemo(() => {
    const errors = [];
    if (!areSitesPositiveIntegers) errors.push("sitesNotPositiveIntegers");
    if (!areSitesIncreasing) errors.push("sitesNotIncreasing");
    if (!areDaysPositive) errors.push("daysNotPositive");
    if (!areDaysIncreasing) errors.push("daysNotIncreasing");
    return errors;
  }, [areSitesPositiveIntegers, areSitesIncreasing, areDaysPositive, areDaysIncreasing]);

  useEffect(() => {
    setHasErrors(errorsArray);
  }, [errorsArray, setHasErrors]);

  const alerts = [
    {
      condition: !areSitesPositiveIntegers,
      message: "Site counts must be positive whole numbers.",
    },
    {
      condition: !areSitesIncreasing,
      message: "Number of sites should increase from left to right.",
    },
    {
      condition: !areDaysPositive,
      message: "Days must be positive numbers.",
    },
    {
      condition: !areDaysIncreasing,
      message: "Days should increase from left to right.",
    },
  ];

  return (
    <SeparateContainer>
      <Container id="activation-profiles">
        <SectionHeader>Activation Profiles</SectionHeader>
        <div
          style={{
            paddingInline: "20px",
            paddingTop: "10px",
            paddingBottom: "20px",
          }}
        >
        <Typography>
          {studyPlanType === SCENARIO_A ? (
            'Site ramp up is modeled using the below data. Both the number of sites and days can be updated to match the site ramp up that is expected in this country for this specific study. The number of sites is the cumulative number of sites activated by that time point. The days is the days since the First Site Activation. Ensure that the numbers (both # of sites and days) increase from left to right with the rightmost values indicating when all sites are initiated. The total number of sites needs to equal the upper bound of sites included for the country.'
          ) : studyPlanType === SCENARIO_B ? (
            'Site ramp up is modeled using the below data. Both the number of sites and days can be updated to match the site ramp up that is expected in this country for this specific study. The number of sites is the cumulative number of sites activated by that time point. The days is the days since the First Site Activation. Ensure that the numbers (both # of sites and days) increase from left to right with the rightmost values indicating when all sites are initiated. The total number of sites needs to equal the total number of sites included for the country.'
          ) : null}
        </Typography>
          <div style={{ display: "grid", gridGap: "1em" }}>
            {alerts
              .filter((alert) => alert.condition)
              .map((alert, index) => (
                <Alert
                  key={index}
                  severity="error"
                >
                  {alert.message}
                </Alert>
              ))}
          </div>
          <ActivationProfilesTable
            activationProfiles={activationProfiles}
            country={country}
            siteGroupId={siteGroupId}
            FSIV={FSIV}
            countryId={countryId}
          />
        </div>
      </Container>
    </SeparateContainer>
  );
};

export default SiteGroupActivationProfiles;

const Container = styled.div``;