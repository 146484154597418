import { LinearProgress } from "@material-ui/core";
import React, { useState } from "react";
import API from "../../../../app/utils/api";
import FileUploader from "../../../../app/components/file.uploader.excel";
import CustomModal from "../../../../app/components/modal.no.close";
import styled from "styled-components";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import * as XLSX from "xlsx";
import PrimaryButton from "../../../../app/components/buttons/primary";
import SecondaryButton from "../../../../app/components/buttons/secondary";

const UploadContractModal = ({ onContinue, ...props }) => {
  const { showSnackbar } = useSnackbar();
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiStatus, setApiStatus] = useState(null);
  const [uploadErrors, setUploadErrors] = useState([]);

  const processSheetData = (worksheet) => {
    // Convert worksheet to array of arrays for easier processing
    const sheet = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: null });
    
    const assessments = [];
    const visits = [];
    let visitNames = [];
    let visitCosts = [];

    // Find the visits row (where column B is "Procedures")
    const visitsRowIndex = sheet.findIndex(row => row[1] === "Procedures");
    if (visitsRowIndex === -1) {
      throw new Error("Could not find visits row with 'Procedures' in column B");
    }

    // Get visit names (starting from column E, excluding the last "Total" column)
    visitNames = sheet[visitsRowIndex].slice(4, -1).filter(name => name != null);
    // Find the visit costs row (where column B is "Total Cost Per Visit")
    const costsRowIndex = sheet.findIndex(row => row[1] === "Total Cost Per Visit");
    if (costsRowIndex === -1) {
      throw new Error("Could not find costs row with 'Total Cost Per Visit' in column B");
    }

    // Get visit costs (starting from column E, excluding the last "Total" column)
    visitCosts = sheet[costsRowIndex].slice(4, 4 + visitNames.length)
    .map(cost => typeof cost === 'number' ? cost : parseFloat(cost))
    .filter(cost => !isNaN(cost));
    // Validate that we have matching numbers of names and costs
    if (visitNames.length !== visitCosts.length) {
      throw new Error("Mismatch between number of visit names and visit costs");
    }

    // Create visits array
    visitNames.forEach((visitName, index) => {
      visits.push({
        visitName,
        visitCost: visitCosts[index]
      });
    });

    // Process assessments (any row with a value in column A)
    sheet.forEach(row => {
      if (row[0]) { // If there's a value in column A
        const assessmentName = row[0];
        const assessmentCost = typeof row[3] === 'number' ? row[3] : parseFloat(row[3]);
        
        if (!isNaN(assessmentCost)) {
          assessments.push({
            assessmentName,
            assessmentCost
          });
        }
      }
    });

    return { assessments, visits };
  };

  const handleChange = async (files) => {
    if (files.length === 0) return;
    handleUpload(files);
  };

  const handleUpload = async (files) => {
    if (files.length === 0) return;

    setLoading(true);
    setProgress(0);
    setStatus("Processing site contract...");
    setUploadErrors([]);

    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { 
          type: "array",
          cellDates: true,
          cellNF: true,
          cellStyles: true
        });
        
        // Find the 'Per Procedure' sheet
        const worksheet = workbook.Sheets['Per Procedure'];
        if (!worksheet) {
          throw new Error("Could not find 'Per Procedure' sheet in the workbook");
        }

        try {
          const { assessments, visits } = processSheetData(worksheet);
          
          if (assessments.length === 0) {
            throw new Error("No valid assessment data found in the sheet");
          }

          const siteData = {
            scheduleOfEventsId: props.scheduleId,
            siteNumber: props.siteNumber,
            siteId: props.siteId,
            assessments,
            visits
          };

          setProgress(50);
          console.log(siteData);
          const response = await API.uploadContract(props.studyId, siteData);
          
          if (response.status === 200) {
            showSnackbar("Site contract uploaded successfully", "success");
            setProgress(100);
            setStatus("Upload completed.");
            setApiStatus(200);
          } else {
            showSnackbar("Failed to upload site contract", "error");
            setProgress(100);
            setStatus("Upload failed.");
            setApiStatus(response.status);
            setUploadErrors([response.data?.message || "Unknown error occurred"]);
          }
        } catch (error) {
          setUploadErrors([error.message]);
          setProgress(100);
          setStatus("Upload failed.");
          setApiStatus("error");
        }
      };
      reader.readAsArrayBuffer(files[0]);
    } catch (error) {
      showSnackbar("Failed to process file", "error");
      setLoading(false);
      setUploadErrors([error.message]);
    }
  };

  const handleContinue = () => {
    props.onClose();
    onContinue();
  };

  return (
    <CustomModal
      size={700}
      {...props}
      onClose={!loading && props.onClose}
      aria-labelledby="Upload Site Contract"
      aria-describedby="upload-contract"
      title="Upload Site Contract"
    >
      <Container>
        {!loading && (
          <VContainer>
            <FileUploader
              acceptedFiles={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": []
              }}
              onFileUpload={handleChange}
            />
          </VContainer>
        )}
        {loading && (
          <VContainer>
            {status !== "Upload completed." && status !== "Upload failed." && (
              <LinearProgress variant="determinate" value={progress} />
            )}
            <StatusText>{status}</StatusText>
            {uploadErrors.length > 0 && (
              <ErrorContainer>
                <ErrorTitle>Errors Found:</ErrorTitle>
                {uploadErrors.map((error, index) => (
                  <ErrorText key={index}>{error}</ErrorText>
                ))}
              </ErrorContainer>
            )}
          </VContainer>
        )}
        <ButtonContainer>
          {loading && apiStatus === 200 ? (
            <PrimaryButton onClick={handleContinue}>Continue</PrimaryButton>
          ) : (
            <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
          )}
        </ButtonContainer>
      </Container>
    </CustomModal>
  );
};

export default UploadContractModal;

const VContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-top: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 1em;
  padding-bottom: 0em;
  padding-top: 0;
  max-height: 75vh;
  overflow-y: auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
`;

const StatusText = styled.div`
  font-weight: 500;
  margin-bottom: 1em;
`;

const ErrorContainer = styled.div`
  background-color: #fff3f3;
  border: 1px solid #ffcdd2;
  border-radius: 4px;
  padding: 1em;
  margin-top: 1em;
`;

const ErrorTitle = styled.div`
  color: #d32f2f;
  font-weight: 500;
  margin-bottom: 0.5em;
`;

const ErrorText = styled.div`
  color: #d32f2f;
  font-size: 0.9em;
  margin-bottom: 0.25em;
`;