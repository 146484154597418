import React, { useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import SaveAsModal from "./save.as.modal";
import { makeStyles } from "@material-ui/core/styles";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const OptionsMenu = ({ anchor, open, onClose }) => {
  const classes = useStyles();
  const { updateStudyPlan, studyPlan } = useStudyPlan();
  const { studyPlanTypeDetail } = studyPlan;
  const [openModal, setOpenModal] = useState(false);

  const handleUpdateStatus = (status) => {
    updateStudyPlan({ scenarioStatus: status });
    onClose();
  };

  const handleDraftSave = () => {
    updateStudyPlan({
      scenarioStatus: "Draft",
      studyPlanVersion: studyPlan.studyPlanVersion + 1
    });
    setOpenModal(false);
  };

  const handleDraftOptionClick = () => {
    setOpenModal(true);
    onClose();
  };

  const menuItems = {
    default: [
      { label: "Draft", action: () => handleDraftOptionClick() },
      { label: "Baseline Plan", action: () => handleUpdateStatus("Baseline Plan") },
      { label: "Ongoing Study", action: () => handleUpdateStatus("Ongoing Study") },
      { label: "Post-Proposal", action: () => handleUpdateStatus("Post-Proposal") },
      { label: "Proposal", action: () => handleUpdateStatus("Proposal") }
    ],
    remodel: [
      { label: "Draft", action: () => handleDraftOptionClick() },
      { label: "Baseline Plan", action: () => handleUpdateStatus("Baseline Plan") }
    ],
    combo: [
      { label: "Draft-Combo", action: () => handleDraftOptionClick() },
      { label: "Baseline Plan", action: () => handleUpdateStatus("Baseline Plan") },
      { label: "Ongoing Study", action: () => handleUpdateStatus("Ongoing Study") },
      { label: "Post-Proposal", action: () => handleUpdateStatus("Post-Proposal") },
      { label: "Proposal", action: () => handleUpdateStatus("Proposal") }
    ]
  };

  const getMenuItems = () => {
    if (["Duration", "Rates", "Cohort (D)"].includes(studyPlanTypeDetail))
      return menuItems.default;
    if (["Remodel (D)", "Remodel (R)"].includes(studyPlanTypeDetail))
      return menuItems.remodel;
    if (["Combination (D)", "Combination (R)"].includes(studyPlanTypeDetail))
      return menuItems.combo;
    return [];
  };

  return (
    <div>
      <Popper
        open={open}
        anchorEl={anchor}
        placement="bottom-end"
        disablePortal={true}
      >
        <Paper classes={classes}>
          {getMenuItems().map(({ label, action }) => (
            <MenuItem
              key={label}
              onClick={action}
            >
              {label}
            </MenuItem>
          ))}
        </Paper>
      </Popper>
      <SaveAsModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onConfirm={handleDraftSave}
        plan={studyPlan}
      />
    </div>
  );
};

export default OptionsMenu;

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    padding: "1em 0",
    border: "1px solid #e6e7e8",
  },
}));